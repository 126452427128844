import Vue from 'vue'
// Lib imports
import axios from 'axios'
var production = true
let hostname = location.hostname;
Vue.prototype.$app_mode = "production"
var SERVER = "http://localhost:9002"
if(production) {
  if(Vue.prototype.$app_mode == "production")
      SERVER = "https://serv123.winz88.com"
  else if(Vue.prototype.$app_mode == "local")
      SERVER = "http://"+hostname+":9494"
}
Vue.prototype.$http = axios.create({ 
  baseURL: SERVER + '/api/v1/',
  headers: {
    Systemkey: 'IYm_9qaw-MsBxqRzc7wcTFIq4BtIt-jc6Zhu9LIoot4'  }
})

Vue.prototype.$fileserver = SERVER + '/public/'
//serv123.winz88.com - urda
// bs1.winz88.com - manaoag
// bs2.winz88.com - lingayenb
// bs3.winz88.com - binalonan