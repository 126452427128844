<template>

  <v-dialog
    v-model="show"
    persistent
    fullscreen
    >
    <v-card>
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-fencing</v-icon><span class="va-underline">CONTROLLER</span>
            <v-spacer/>
            <v-btn @click="emitToParent('close')" small><v-icon  >mdi-close</v-icon> CLOSE</v-btn>
            
          </v-toolbar>
        <v-card-text>
           
              <v-row class="mt-5">
                  <v-col cols="6">
                         <div class="text-h4">FIGHT NO.: {{fight.fight_no}}</div>
                </v-col>
               <v-col cols="6">
                      <div class="text-h4 text-right">TOTAL BETS: {{$money(TOTALBETS)}}</div>
                </v-col>
                </v-row>
                <v-row>
                <v-col cols="6">
                  <v-card>
                     <v-toolbar>
                      BET CONTROLLER
                      <v-spacer/>
                      <div  :class="STATUS_COLOR(fight.status) + ' text-h6'"> {{fight.status}}</div>
                     </v-toolbar>
                     <v-card-text>
                    
                      <v-row>
                      <!-- <v-btn color="info" @click="fight.status = 'Ready', SaveFight()" large>READY</v-btn>
                      <v-spacer/> -->
                      <v-btn color="success" @click="fight.status = 'Open', SaveFight()" large :disabled="OPEN">OPEN</v-btn>
                      <v-spacer/>
                      <v-btn color="warning" @click="fight.status = 'Last Call', SaveFight()" large :disabled="LASTCALL">LAST CALL</v-btn>
                      <v-spacer/>
                      <v-btn color="error" @click="fight.status = 'Closed', SaveFight()" large :disabled="CLOSED">CLOSED</v-btn>
                    </v-row>
                     </v-card-text> 
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card>
                     <v-toolbar>
                      RESULT CONTROLLER
                      <v-spacer/>
                      <div :class="STATUS_COLOR(fight.status)">{{fight.result?fight.result:"...."}} </div>
                     </v-toolbar>
                     <v-card-text>
                   
                      <v-row>
                        <v-btn color="error" :disabled="ISDISABLED"  large @click="select_result='Cancelled', confirm=true">CANCELLED</v-btn>
                      <v-spacer/>
                      <v-btn color="gray"  :disabled="ISDISABLED" large @click="select_result='Draw', confirm=true"> DRAW</v-btn>
                      <v-spacer/>
                      <v-btn color="gray"   :disabled="ISDISABLED"  style="color: red" large @click="select_result='Meron', confirm=true">MERON WIN</v-btn>
                      <v-spacer/>
                      <v-btn color="gray"  :disabled="ISDISABLED"  large  style="color: blue" @click="select_result='Wala', confirm=true">WALA WIN</v-btn>
                    </v-row>
                     </v-card-text> 
                  </v-card>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-10">
                <!-- {{fight}} -->
              </v-row>
              <v-row class="mb-2">
                  <v-col cols="6">
                    <v-btn block color="meron" dark tile x-large >MERON</v-btn>
                    <div class="text-h5 text-center pa-1 font-weight-bold">{{$money(current_fight.meron_collection)}}</div>
                    <div class="text-center"  v-if="LOCKBTN">
                      <v-btn
                          @click="changeIcon('meron')"
                          fab
                          dark
                          :color="meron_betting?'success':'dark'"
                          small
                        >
                        <v-icon>mdi-{{meron_betting?'lock-open-variant-outline':'lock'}}</v-icon>
                      </v-btn>
                      <br/>
                      {{meron_betting?'Betting Open':'Betting Locked'}}
                    </div>
                </v-col>
               <v-col cols="6">
                  <v-btn color="wala" block tile x-large dark>WALA</v-btn>
                  <div class="text-h5 text-center pa-1 font-weight-bold">{{$money(current_fight.wala_collection)}}</div>
                  <div class="text-center" v-if="LOCKBTN">
                      <v-btn
                          @click="changeIcon('wala')"
                          :color="wala_betting?'success':'dark'"
                          fab
                          dark
                          small
                        >
                        <v-icon>mdi-{{wala_betting?'lock-open-variant-outline':'lock'}}</v-icon>
                      </v-btn>
                      <br />
                      {{wala_betting?'Betting Open':'Betting Locked'}}
                    </div>
                </v-col>
                </v-row>
                <v-divider/>
                <v-row class="mt-2">
                  <v-col cols="6">
                    <v-btn block  tile  disabled>PAYOUT</v-btn>
                    <div class="text-center text-h6 pt-4"> {{$IsNumCon(current_fight.id!=undefined?current_fight.meron_meta.ratio:"0")*100}}%</div>
                </v-col>
               <v-col cols="6">
                  <v-btn  block tile disabled>PAYOUT</v-btn>
                  <div class="text-center text-h6 pt-6"> {{$IsNumCon(current_fight.id!=undefined?current_fight.wala_meta.ratio:"0")*100}}%</div>
                </v-col>
                </v-row>
        </v-card-text>
    </v-card>
  <va-result-confirm :show="confirm" :result="select_result" @DialogEvent="confirmEvent"/>
  <va-loading :show="loading"/>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    },
    entry: {
      type:Object,
      default:()=>{}
    },
    state: {
        type: String,
        default: "new"
    }
  },
  data: () => ({
      valid: true,
      confirm: false,
      loading: false,
      wala_betting: 1,
      meron_betting: 1,
      select_result:"",
      fight:{},
      current_fight: {},
      meron:{meta:{}},
      wala:{meta:{}},
      socket:null,
      textRules: [
        v => !!v || 'Field is required'
      ],
  }),
  computed: {
    ISDISABLED() {
        if(this.fight.status == 'Closed') {
          return false
        }
      return true
    },
      BTN_TEXT() {
          if(this.state == 'new')
            return "READY"
         else 
            return "SAVE"
      },
      LOCKBTN() {
        if(this.fight.status == 'Last Call' || this.fight.status == 'Open' || this.fight.status == 'Finished') {
          return true
        }
        return false
      },
      OPEN() {
        if(this.fight.status == 'Last Call' || this.fight.status == 'Open' || this.fight.status == 'Closed' || this.fight.status == 'Finished') {
          return true
        }
        return false
      },
      CLOSED() {
        if(this.fight.status == 'Closed' || this.fight.status == 'Ready' || this.fight.status == 'Open' || this.fight.status == 'Finished') {
          return true
        }
        return false
      },
      LASTCALL() {
        if(this.fight.status == 'Last Call' || this.fight.status == 'Closed' || this.fight.status == 'Ready' || this.fight.status == 'Finished') {
          return true
        }
        return false
      },
      TOTALBETS(){
          return this.$IsNum(this.current_fight.meron_collection) + this.$IsNum(this.current_fight.wala_collection)
      },
      BREEDERS() {
        return this.$store.state.breeders
      }
  },
  watch: {
    show(v) {
      if(v){
        if(this.state == 'new') {
          this.fight={}
          this.meron={meta:{}}
          this.wala = {meta:{}}
        } else {
          this.getFight()

          this.socket = new WebSocket("ws://localhost:8888/ws")
      this.socket.onopen = ()=> {
        console.log("Connected!!")
      }
      this.socket.onclose = () => {
          console.log("closed");
      }
      this.socket.onmessage  = (e)=> {
        if(e.data != undefined) {
          var _data = JSON.parse(e.data)
          console.log(_data.item)
          this.current_fight = _data.item
        
        }
      }
        
        }
      } 
    }
  },
  methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.emitToParent(this.state)
        }
      },
      changeIcon(e) {
        if(e=="meron") {
          if(this.meron_betting) {
            this.meron_betting = 0
          } else {
            this.meron_betting = 1
          }
        } else {
          if(this.wala_betting) {
            this.wala_betting = 0
          } else {
            this.wala_betting = 1
          }
        }
       this.SaveFight()
      },
      emitToParent(action) {
        this.socket.close()
        this.$emit('DialogEvent', action)
      },
      confirmEvent(data){
        this.confirm = false
       
        if(data.action !="close") {
          this.loading = true
            this.fight.result = data.action
            this.fight.status = "Finished"
            this.Save()
        }
      },
       getFight(){
         console.log("getFight")
          this.$http.post("fight/get", {id: this.entry.id}).then(response => {
            response.data.status? this.fight = response.data.item:this.fight={} 
              this.meron= this.fight.meron
            this.wala =  this.fight.wala
            this.wala_betting =this.fight.wala_meta.betting
            this.meron_betting =this.fight.meron_meta.betting
            console.log(response.data.item)
        }).catch(e => {
          console.log(e)
        })
      },

      SaveFight: function() {
        let method = 'fight/update'
        this.fight.wala_meta.betting = this.wala_betting
        this.fight.meron_meta.betting = this.meron_betting
        this.$http.post(method, this.fight).then(response => {
           response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
           this.getFight()
           
        }).catch(e => {
        console.log(e)
        })
     },
     Save(){
        this.fight.meron = this.meron
        this.fight.wala = this.wala
        this.$http.post('fight/set_result', this.fight).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
            this.loading = false
        }).catch(e => {
        console.log(e)
        })
     },

  }
}
</script>
