<template>
 
  <v-dialog
    v-model="show"
    persistent
    max-width="520">
    <v-card>
    <v-toolbar class="mid-font" flat dense >
        <v-icon left color="accent">mdi-draw</v-icon><span class="va-underline">Your Signature </span>
        <v-spacer/>
    <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
    </v-toolbar>
        <v-card-text class="mb-10">

              <VueSignaturePad
            id="signature"
            width="100%"
            height="250px"
            ref="signaturePad"
            :options="{ penColor: '#c0f', onBegin }"
          />
        </v-card-text>
         <v-card-actions>
                <v-btn color="warning" small rounded text @click="undo">Reset</v-btn>
            <v-spacer></v-spacer>
            <v-btn  small color="success"  depressed  @click="save">Proceed</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    props: {
    show: {
        type: Boolean,
        default: false
    }
  },
  data: () => ({
      signature:null
  }),
  methods: {
      onBegin() {
          this.$refs.signaturePad.resizeCanvas()
      },
    emitToParent (data) {
        this.$emit('DialogEvent', data)
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    save() {
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
        if(!isEmpty) {
           this.emitToParent(data)
        }
    },
    change() {
      this.options = {
        penColor: "#00f"
      };
    },
    resume() {
      this.options = {
        penColor: "#c0f"
      };
    }
  }
};
</script>

<style>
#signature {
  border-bottom: double 1px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>
