<template>

  <v-dialog
    v-model="show"
    persistent
    fullscreen
    >
    <v-card>
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-fencing</v-icon><span class="va-underline">CONTROLLER</span>
            <v-spacer/>
            <v-btn @click="emitToParent('close')" small><v-icon  >mdi-close</v-icon> CLOSE</v-btn>
          </v-toolbar>
        <v-card-text>
           
              <v-row class="mt-5">
                  <v-col cols="6">
                         <div class="text-h5">FIGHT NO.: {{fight.fight_no}}</div>
                </v-col>
               <!-- <v-col cols="6">
                      <div class="text-h5">STATUS: {{fight.status}}</div>
                </v-col> -->
                </v-row>
                <v-row>
                <v-col cols="6">
                  <v-card>
                     <v-toolbar>
                      STATUS
                      <v-spacer/>
                      <div :class="STATUS_COLOR(fight.status) + ' text-h4'"> {{fight.status}}</div>
                     </v-toolbar>
                     
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card>
                     <v-toolbar>
                      RESULT 
                      <v-spacer/>
                      <div class="text-h4">{{fight.result?fight.result:"...."}} </div>
                     </v-toolbar>
                     
                  </v-card>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-2">
                <!-- <div class="text-h5">FIGHT SUMMARY</div> -->
              </v-row>
              <v-row class="mb-2">
                  <v-col cols="6">
                    <v-btn block color="meron" dark tile x-large >MERON</v-btn>
                    <div class="text-h5 text-center pa-1 font-weight-bold">{{$money(fight.meron_collection)}}</div>
                   
                </v-col>
               <v-col cols="6">
                  <v-btn color="wala" block tile x-large dark>WALA</v-btn>
                  <div class="text-h5 text-center pa-1 font-weight-bold">{{$money(fight.wala_collection)}}</div>
                 
                </v-col>
                </v-row>
                <v-divider/>
                <v-row class="mt-2">
                  <v-col cols="6">
                    <v-btn block  tile  disabled>WIN BY 100</v-btn>
                    <div class="text-center text-h6 pt-4"> {{$IsNumCon(fight.id!=undefined?fight.meron_meta.ratio:"0") * 100}}</div>
                </v-col>
               <v-col cols="6">
                  <v-btn  block tile disabled>WIN BY 100</v-btn>
                  <div class="text-center text-h6 pt-6"> {{$IsNumCon(fight.id!=undefined?fight.wala_meta.ratio:"0") * 100}}</div>
                </v-col>
                </v-row>
        </v-card-text>
    </v-card>
  <va-result-confirm :show="confirm" :result="select_result" @DialogEvent="confirmEvent"/>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    },
    entry: {
      type:Object,
      default:()=>{}
    },
    state: {
        type: String,
        default: "new"
    }
  },
  data: () => ({
      valid: true,
      confirm: false,
      wala_betting: 1,
      meron_betting: 1,
      select_result:"",
      fight:{},
      current_fight: {},
      meron:{meta:{}},
      wala:{meta:{}},
      socket:null,
      textRules: [
        v => !!v || 'Field is required'
      ],
  }),
  computed: {
      BTN_TEXT() {
          if(this.state == 'new')
            return "READY"
         else 
            return "SAVE"
      },
      OPEN() {
        if(this.fight.status == 'Last Call' || this.fight.status == 'Open' || this.fight.status == 'Closed') {
          return true
        }
        return false
      },
      CLOSED() {
        if(this.fight.status == 'Closed' || this.fight.status == 'Ready' || this.fight.status == 'Open' ) {
          return true
        }
        return false
      },
      LASTCALL() {
        if(this.fight.status == 'Last Call' || this.fight.status == 'Closed' || this.fight.status == 'Ready') {
          return true
        }
        return false
      },
      BREEDERS() {
        return this.$store.state.breeders
      }
  },
  watch: {
    show(v) {
      if(v){
        if(this.state == 'new') {
          this.fight={}
          this.meron={meta:{}}
          this.wala = {meta:{}}
        } else {
          this.getFight()

     
        
        }
      } 
    }
  },
  methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.emitToParent(this.state)
        }
      },
      changeIcon(e) {
        if(e=="meron") {
          if(this.meron_betting) {
            this.meron_betting = 0
          } else {
            this.meron_betting = 1
          }
        } else {
          if(this.wala_betting) {
            this.wala_betting = 0
          } else {
            this.wala_betting = 1
          }
        }
       this.SaveFight()
      },
      emitToParent(action) {
       // this.socket.close()
        this.$emit('DialogEvent', action)
      },
      confirmEvent(data){
        this.confirm = false
        if(data.action !="close") {
            this.fight.result = data.action
            this.fight.status = "Finished"
            this.Save()
        }
      },
       getFight(){
         console.log("getFight")
          this.$http.post("fight/get", {id: this.entry.id}).then(response => {
            response.data.status? this.fight = response.data.item:this.fight={} 
              this.meron= this.fight.meron
            this.wala =  this.fight.wala
            this.wala_betting =this.fight.wala_meta.betting
            this.meron_betting =this.fight.meron_meta.betting
            console.log(response.data.item)
        }).catch(e => {
          console.log(e)
        })
      },

      SaveFight: function() {
        let method = 'fight/update'
        this.fight.wala_meta.betting = this.wala_betting
        this.fight.meron_meta.betting = this.meron_betting
        this.$http.post(method, this.fight).then(response => {
           response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
           this.getFight()
        }).catch(e => {
        console.log(e)
        })
     },
     Save(){
        this.fight.meron = this.meron
        this.fight.wala = this.wala
        this.$http.post('fight/set_result', this.fight).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
              this.$emit('DialogEvent', "")
        }).catch(e => {
        console.log(e)
        })
     },

  }
}
</script>
