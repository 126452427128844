import TableCSVExporter from '@/plugins/tabletocsv'
import {
    mapMutations
  } from 'vuex'
export const API_MIXINS = {
    methods: {
        ...mapMutations([
            'setAlert',
            'setAlertColor', 
            'setAlertText',
            'setGameSetting',
            'setOverlay',
            'setAllowStatBox',
            'setAllowSetUp',
            'setAdmin',
            'setOwners',
            'setBreeders',
            'setAccountTransactions'
        ]),
        VA_ALERT(color, message) {
                this.setAlertText(message)
                this.setAlertColor(color)
                this.setAlert(true)
        },
        SET_PERMISSION(action){
          this.$session.get('role') == "Administrator"?this.setAdmin(true):this.setAdmin(false)
          if('game_set_up' == action) {
              this.$session.get('role') == "Administrator"?this.setAllowSetUp(true):this.setAllowSetUp(false)
          }
          if('game_stat_box'  == action) {
            this.$session.get('role') == "Administrator"?this.setAllowStatBox(true):this.setAllowStatBox(false)
         }
        },
        GET_ACCOUNT_TRANSACTIONS(query){
         // console.log(query)
          this.$http.post("wallet/get_transactions", query).then(response => {
           response.data.items != null?this.setAccountTransactions(response.data.items):this.setAccountTransactions([])
           //console.log(response.data.items)
         }).catch(e => {
           console.log(e.data)
         })
     },
        GET_OWNERS(){
          this.$http.get("owner/list").then(response => {
            response.data.items != null?this.setOwners(response.data.items):this.setOwners({})
        }).catch(e => {
        console.log(e.data)
        })
       },
       GET_BREEDERS(){
        this.$http.get("breeder/list").then(response => {
          response.data.items != null?this.setBreeders(response.data.items):this.setBreeders({})
      }).catch(e => {
      console.log(e.data)
      })
     },
      STATUS_COLOR(status){
         switch(status) {
            case "Submitted":
              return "text-info"
            case "Win":
              return "text-success"
              case "Lost":
                return "text-error" 
            case "Cash Out":
            return "text-warning"
            case "Buy Ticket":
            return "text-info"
            case "Active":
            return "text-success"
            case "Deactive":
            return "text-gray"
            case "Upcoming":
            return "text-info"
            case "Ongoing":
            return "text-success"
            case "Complete":
            return "text-accent"
            case "Cancelled":
            return "text-gray"
            case "Ready":
            return "text-info"
            case "Open":
            return "text-success"
            case "Last Call":
            return "text-warning"
            case "Closed":
              return "text-error"
            case "Finished":
                return "text-accent"
                case "Meron":
                  return "text-error"
                  case "Wala":
                    return "text-info"
            default:
              return ""
         }
      },
       DownloadToCsv (eleID, filename) {
        const dataTable = document.getElementById(eleID)
        const exporter = new TableCSVExporter(dataTable)
        const csvOutput = exporter.convertToCSV()
        const csvBlob = new Blob([csvOutput], { type: 'text/csv' })
        const blobUrl = URL.createObjectURL(csvBlob)
        const anchorElement = document.createElement('a')
  
        anchorElement.href = blobUrl
        anchorElement.download =  filename+ '.csv'
        anchorElement.click()
  
        setTimeout(() => {
          URL.revokeObjectURL(blobUrl)
        }, 500)
      }
    } ///eof methods
}