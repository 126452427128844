export const set = property => (state, payload) => (state[property] = payload)

export const toggle = property => state => (state[property] = !state[property])

export default {
  setDrawer: set('drawer'),
  setResponsive: set('responsive'),
  setLoggedIn: set('is_logged'),
  setAlert: set('show_alert'),
  setAlertColor: set('alert_color'),
  setAlertText: set('alert_text'),
  setShowBooking: set('show_booking'),
  setReservedRooms: set('reserved_rooms'),
  setReservation: set('reservation'),
  setRoom: set('room'),
  setRoles: set('roles'),
  setBranches:set('branches'),
  setFranchise: set("franchise"),
  setFranchiseelist: set('franchiseelist'),
  setRoomProperties: set('room_properties'),
  setRoomList:  set('roomlist'),
  setBreeders:  set('breeders'),
  setReservationList: set('reservation_list'),
  setInclusions:set('inclusions'),
  setCategories:set('product_categories'),
  setProducts:set('products'),
  setShowPaymetForm: set('show_payment_form'),
  setInitCheckin: set('init_checkin'),
  setGameSetting:set('game_setting'),
  setOverlay:  set('overlay'),
  setOpenDraws:  set('open_draws'),
  setAllowSetUp:  set('allow_set_up'),
  setAllowStatBox:  set('allow_stat_box'),
  setAdmin:  set('is_admin'),
  setOwners:  set('owners'),
  setAccountTransactions: set('account_transactions')
}
