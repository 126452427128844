<template>
    <v-dialog
    v-model="show"
    persistent
    width="470">
   
    <v-card color="light_gray">
        <v-toolbar class="mid-font" color="error" dense dark >
            <v-icon left >mdi-cancel</v-icon><span class="va-underline">WARNING</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text class="pa-8 def-font">
                <template v-if="state=='events'">
                    <div>
                        <p>This action will remove all <strong>events</strong> in the database.</p>
                      
                    </div>
                </template>
                <template v-if="state=='fights'">
                    <div>
                        <p>This action will remove all <strong>fights</strong> in the database.</p>
                      
                    </div>
                </template>
                <template v-if="state=='bets'">
                    <div>
                        <p>This action will remove all <strong>bets</strong> in the database.</p>
                      
                    </div>
                </template>
                <p><strong>Would you like to proceed?</strong></p>
               <v-divider/>
          </v-card-text>
          <v-card-actions>
               <v-btn small color="warning" @click="emitToParent('close')">
                  Not Now
              </v-btn>
              <v-spacer/>
              <v-btn small color="error" @click="Void()">
                  Proceed
              </v-btn>
          </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { API_MIXINS } from '@/mixins/api_mixins.js'
export default {
    mixins: [API_MIXINS],
  props: {
      show:{
          type: Boolean,
          default: false
      },
      state:{
          type: String,
          default: ""
      }, 
  },
  data () {
      return {
          valid: true,
          entry:{},
           nameRules: [
            v => !!v || '*Required'
        ],
      }
    },
  watch: {
     show(val) {
         if(val) {
             this.entry = {}
         }
     }
  },
  computed:{
   
  },
  methods: {
   
   emitToParent (action) {
      this.$emit('DialogEvent',action )
    },
 
    Void(){
        var param = {}
        param.action = "remove"
        param.table = this.state
         this.$http.post("user/reset",param).then(response => {
              if(response.data.status) {
                  this.VA_ALERT('success',response.data.message) 
                  this.$emit('DialogEvent',"close" )
              } else {
                this.VA_ALERT('error',response.data.message) 
              }
          }).catch(e => {
            console.log(e.data)
          })
    }
    
  }
}
</script>
