import philippines from './philippines'
import occupations from './occupations'
import suffixes from './suffixes'
export default {
  Philippines: philippines,
  occupations: occupations,
  suffixes: suffixes,
  drawer: false, 
  responsive: false,
  systemlogo: '../logo.png',
  game_setting:{},
  loginbg: '../bg.png',
  applogo: '../logo.png',
  galing: '../galing.jpeg',
  campro: '../campro.png',
  is_logged: false,
  show_alert: false,
  overlay: false,
  alert_color: "secondary",
  alert_text: "No message",
  allow_set_up: false,
  allow_stat_box: false,
  is_admin: false,
  roles:["Administrator", "Operator", "Agent"],
  breeders:[],
  owners:[],
  franchise:{},
  franchiseelist:[],
  address_preset: {
    region: "REGION I",
    province: "PANGASINAN",
    municipality: "URDANETA CITY",
    barangay: "POBLACION"
  },
  account_transactions: [],
  action_permissions: [
    {action: "add_branch", description: "Add branch"},
    {action: "update_branch", description: "Update branch"},
    {action: "generate_branch_license", description: "Generate branch license"},
    {action: "update_branch_franchisee", description: "Update branch franchise"},
    {action: "add_franchise", description: "Add franchise"},
    {action: "update_franchise", description: "Update franchise"},
    {action: "wallet_request_top_up", description: "Request wallet top-up"},
    {action: "wallet_verify_top_up", description: "Verify top-up"},
    {action: "wallet_approve_top_up", description: "Approve top-up"},
    {action: "wallet_complete_top_up", description: "Complete top-up request"},
    {action: "load_transfer", description: "Transfer load"}
    
  ],
  page_permissions: [
    {page: "Dashboard", description: "Dashboard"},
    {page: "Account Management", description: "Account Management"},
    {page: "Fight Entries", description: "Fight Entries"},
    {page: "Breeders Management", description: "Breeders Management"},
    {page: "Settings", description: "Settings"},
    {page: "Result Key In", description: "Result Key In"},
    {page: "My Profile", description: "My Profile"},
    {page: "Settings", description: "General Settings"},
    {page: "Collection Summary", description: "(Report) Collection Summary"},
    {page: "Wallet Monitoring", description: "(Report) Wallet Monitoring"},
    {page: "Fight Summary", description: "(Report) Fight Summary"},
  ],
  admin_menus: [
    { title: 'Users Management', icon: 'mdi-account-cog', page:'/users'},
     { title: 'Settings', icon: 'mdi-cogs', page:'/general-settings'},
     { title: 'My Profile', icon: 'mdi-badge-account-horizontal', page:'/profile'}
  ],
  operation_menus: [
     { title: 'Franchisee', icon: 'mdi-account-tie', page:'franchisee'},
     { title: 'Branches', icon: 'mdi-store', page:'/branch'},
     { title: 'Top Up', icon: 'mdi-wallet', page:'/wallet'},
     { title: 'Wallet Transactions', icon: 'mdi-swap-horizontal-bold', page:'/wallet-transactions'}
  ],
   report_menus: [
    { title: 'Per Biller', icon: 'mdi-domain', page:'/per-billers'},
    { title: 'Per Outlet', icon: 'mdi-store', page:'/per-outlet'},
    { title: 'Collection Summary', icon: 'mdi-format-list-bulleted-square', page:'/summary'},
    { title: 'Wallet Monitoring', icon: 'mdi-wallet-outline', page:'/wallet'},
    { title: 'Profit Sharing', icon: 'mdi-share-circle', page:'/wallet'},
    { title: 'Settlement', icon: 'mdi-bank-check', page:'/wallet'},
  ],
  partner_menus: [
    { title: 'Partners', icon: 'mdi-handshake-outline', page:'/partners'},
    { title: 'Partner Reports', icon: 'mdi-format-list-text', page:'/partner-reports'}
  ],
  
}
  