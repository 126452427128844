<template>
     <v-card :class="color" dark class="ma-1">
              <v-card-text style="height: 80px;">
                  <v-icon v-if="icon != null" :color="iconcolor" left large  style="float: left; margin-top: 6px; margin-right: 12px;">{{icon}}</v-icon> 
                  <p style="float: left;">
                  <small  style="font-size: .7em;">{{label}}</small><br />
                  <span class="subtitle-1">{{data}} <sup v-if="sup">{{sup}}</sup></span>
                  </p>
                </v-card-text>
            </v-card>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "secondary"
    },
    icon: {
      type: String,
      default: null
    },
    iconcolor: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    data: {
      type: String,
      default: ''
    },
    sup: {
      type: String,
      default: ''
    }
  }
}
</script>