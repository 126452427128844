export default [ 
    "Jr",
    "Sr",
    "II",
    "III",
    "M.D",
    "Ph.D.",
    "(Ret)",
    "C.P.A",
    "D.D.S",
    "DC",
    "DD",
    "DDSPA",
    "DMD",
    "DO",
    "DPM",
    "DR",
    "DVM",
    "ESP",
    "Esq.",
    "Filho",
    "I",
    "INC",
    "ITF",
    "IV",
    "IX",
    "MDPA",
    "MFCC",
    "MS",
    "ND",
    "Neto",
    "O.D.P.C.",
    "O.F.M.",
    "OBS",
    "OD",
    "P.A.",
    "PC",
    "PRS",
    "Q.C.",
    "R.N.",
    "Sobrinho",
    "THD",
    "V",
    "VI",
    "VMD",
    "VP"
  ]