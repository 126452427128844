<template>

  <v-dialog
    v-model="show"
    persistent
    max-width="400">
    <v-card>
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-account</v-icon><span class="va-underline">{{state=='new'?'Add':'Update'}} Service</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
        <v-card-text>
             <v-form
                ref="form"
                class="mt-4"
                v-model="valid"
                lazy-validation
              >
              <v-row>
                <v-col cols="12">
                  
                  <v-autocomplete
                  v-model="service.beneficiary"
                   :rules="textRules"
                required
                  :items="voters"
                  outlined
                    dense
                    item-text="fullname"
                    return-object
                  label="Beneficiary"
                  ></v-autocomplete>    

                  <v-autocomplete
                  v-model="service.type"
                   :rules="textRules"
                required
                  :items="['Healthcare', 'Financial','Goods', 'Foods', 'Social Work', 'Education', 'Other']"
                  outlined
                    dense
                  label="Service Type"
                  ></v-autocomplete>     
                  <v-textarea rows="3" v-model="service.description"  label="Item Description"   outlined/>

                  <v-autocomplete
                  v-model="service.status"
                   :rules="textRules"
                required
                  :items="['Requested', 'On-process','Claimed']"
                  outlined
                    dense
                  label="Status"
                  ></v-autocomplete>     
                </v-col>
              
              </v-row>
             </v-form>
        </v-card-text>
        <v-card-actions>
                <v-btn color="warning" small rounded text @click="emitToParent('close')">CLOSE</v-btn>
            <v-spacer></v-spacer>
            <v-btn  small color="success" depressed :loading="loading"  @click="validate()">{{BTN_TEXT}}</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    },
    event_id: {
        type: Number,
        default:null
    },
    data: {
        type: Object,
        default: ()=>{}
    },
    voters: {
        type: Array,
        default: ()=>[]
    },
    state: {
        type: String,
        default: "new"
    }
  },
  data: () => ({
      valid: true,
      service:{},
      loading: false,
      date_menu: false,
      newpassword:'',
      event:{},
      show1: false,
      textRules: [
        v => !!v || 'Field is required'
      ],
  }),
  computed: {
      BTN_TEXT() {
          if(this.state == 'new')
            return "ADD"
         else 
            return "Save"
      },
      IS_DISABLED(){
          if(this.event.status == "Finished") {
            return true
          }
          return false
      }
  },
  watch: {
    show (v) {
      if(v) {
       
         if (this.state != 'new') {
          this.service = this.data
          this.getService()
        } else {
          this.$refs.form.reset()
          this.service = {}
        }
      } 
     
    }
  },
  methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.newRequest()
        }
      },
      getService(){

      },
      newRequest(){
        this.loading = true
        let method = 'service/new'
        this.state != 'new'?method='service/update':method = 'service/new'
       this.service.beneficiary_id= this.service.beneficiary.id
        this.$http.post(method, this.service).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
            this.loading = false
            this.emitToParent("close")
        }).catch(e => {
        console.log(e)
        })
      },
    emitToParent (action) {
      this.$emit('DialogEvent', {action: action, service:this.service})
    }

  }
}
</script>
