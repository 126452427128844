<template>

  <v-dialog
    v-model="show"
    persistent
    max-width="400">
    <v-card>
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-account</v-icon><span class="va-underline">{{state=='new'?'New':'Edit'}} Event</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
        <v-card-text>
             <v-form
                ref="form"
                class="mt-4"
                v-model="valid"
                lazy-validation
              >
              <v-row>
                <v-col cols="12">
                  
               <v-text-field
                 v-model="event.name"
                 :disabled="IS_DISABLED"
                  label="Event Name *"
                  outlined
                  dense
                  :rules="textRules"
                required
                ></v-text-field>
                <v-menu
                        v-model="date_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="Date"
                                 :value="event.event_dt"
                                 filled
                                 readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="event.event_dt" no-title scrollable @input="date_menu=false" />
                    </v-menu>
               
                  <v-autocomplete
                  v-model="event.status"
                   :rules="textRules"
                   :disabled="IS_DISABLED"
                required
                  :items="['Upcoming', 'Ongoing','Finished']"
                  outlined
                    dense
                  label="Status"
                  ></v-autocomplete>     
                
                </v-col>
              
              </v-row>
             </v-form>
        </v-card-text>
        <v-card-actions>
                <v-btn color="warning" small rounded text @click="emitToParent('close')">CLOSE</v-btn>
            <v-spacer></v-spacer>
            <v-btn  small color="success" depressed  @click="validate()">{{BTN_TEXT}}</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    },
    event_id: {
        type: Number,
        default:null
    },
    data: {
        type: Object,
        default: ()=>{}
    },
    state: {
        type: String,
        default: "new"
    }
  },
  data: () => ({
      valid: true,
      date_menu: false,
      newpassword:'',
      event:{},
      show1: false,
      textRules: [
        v => !!v || 'Field is required'
      ],
  }),
  computed: {
      BTN_TEXT() {
          if(this.state == 'new')
            return "ADD"
         else 
            return "Save"
      },
      IS_DISABLED(){
          if(this.event.status == "Finished") {
            return true
          }
          return false
      }
  },
  watch: {
    show (v) {
      if(v) {
         if (this.state != 'new') {
          this.getEvent()
        } else {
          this.event = {}
        }
      }
     
    }
  },
  methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.emitToParent(this.state)
          this.show1 = false
        }
      },
      getEvent(){
         this.event = this.data
      },
    emitToParent (action) {
      this.$emit('DialogEvent', {action: action, event:this.event})
    }

  }
}
</script>
