<template>

  <v-dialog
    v-model="show"
    persistent
      fullscreen
    >
    <v-card>
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-account</v-icon><span class="va-underline">{{state=='new'?'New':'Edit'}} Voter's Info</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col  cols="12" sm="6">
              <v-card class="pa-2">
             <v-form
                ref="form"
                class="mt-4"
                autocomplete="off"
                v-model="valid"
                lazy-validation
              >
               <v-text-field
                 v-model="voter.lastname"
                  label="Last Name *"
                  outlined
                  dense
                  :rules="textRules"
                required
                ></v-text-field>

                <v-text-field
                 outlined
                  dense
                 v-model="voter.firstname"
                  label="First Name"
                  :rules="textRules"
                required
                ></v-text-field>

                <v-text-field
                 outlined
                  dense
                 v-model="voter.middlename"
                  label="Middle Name"
                  :rules="textRules"
                required
                ></v-text-field>

                <v-text-field
                 outlined
                  dense
                 v-model="voter.ext"
                  label="Ext"
                required
                ></v-text-field>

                <v-autocomplete
                  v-model="voter.prec"
                  item-text="Precinct"
                  return-object
                   :rules="textRules"
                required
                  :items="precincts"
                  outlined
                    dense
                  label="Select Precinct"
                  ></v-autocomplete>
      
             </v-form>
             <v-divider/>
             <v-toolbar dense elevation="0">
                <v-btn color="warning" small rounded text @click="emitToParent('close')">CLOSE</v-btn>
                <v-spacer></v-spacer>
                <v-btn  small color="success"  depressed  @click="validate()">{{BTN_TEXT}}</v-btn>
              </v-toolbar>
            </v-card>
             </v-col>
            <v-col cols="12" sm="6" v-if="state=='update'">
                <div class="pa-1 mt-4 text-h6">Claimed From Events:</div>
                <v-divider/>
                <div class="pa-1 text-body-2"  v-for="(item, index) in claims" :key="index">{{index+1}}. {{item.name}} - <em>{{$moment(item.event_dt).format('MMM DD, YYYY hh:mm A')}}</em></div>
           
                <div class="pa-1 mt-4 text-h6">Claimed Community Services:</div>
                <v-divider/>
                <div class="pa-1 text-body-2"  v-for="(item, index) in services" :key="'s'+index">
                  <div class="pa-1">
                    <span class="font-weight-bold">{{item.type}} </span>| {{item.description}} <br/>
                    <em class="text-info">{{$moment(item.claimed_dt).format('MMM DD, YYYY hh:mm A')}}</em>
                  </div>
                  <v-divider/>
                </div>
              
              </v-col>
            </v-row>
        </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    },
    voter_id: {
        type: Number,
        default:null
    },
    precincts: {
        type: Array,
        default: ()=>[]
    },
    selected: {
        type: Object,
        default: ()=>{}
    },
    state: {
        type: String,
        default: "new"
    }
  },
  data: () => ({
      valid: true,
      newpassword:'',
      voter:{},
      claims: {},
      services:[],
      show1: false,
      textRules: [
        v => !!v || 'Field is required'
      ],
  }),
  computed: {
    WIDTH() {
      if(this.state == 'new')
            return 400
         else 
            return 800
    },
      BTN_TEXT() {
          if(this.state == 'new')
            return "ADD"
         else 
            return "SAVE"
      },
      
  },
  watch: {
    show (v) {
      if (!v) {
        this.newpassword = ''
        this.show1=false
       
      } else {
    
        //this.getAccount()
        if(this.state == 'update') {
      
          this.getVoter()
        
        }
      }
    }
  },
  methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.emitToParent(this.state)
          this.show1 = false
        }
      },
      myServices(){
        this.services = []
        console.log({status:"Claimed", beneficiary_id: this.voter.id})
        this.$http.post("service/per_account",{status:"Claimed", beneficiary_id: this.voter.id}).then(response => {
          response.data.items !=null?this.services = response.data.items:this.services = []
          console.log(response.data.items)
              }).catch(e => {
                console.log(e.data)
              })
      },
    myClaims(){
    this.$http.post("claims/get",{status:"Claimed", id: this.voter.id}).then(response => {
      response.data.claims !=null?this.claims = response.data.claims:this.claims = []
          }).catch(e => {
            console.log(e.data)
          })
  },
  getVoter(){
        console.log({status:"by_id", id: this.selected.id})
          this.$http.post("voter/view", {status:"by_id", id: this.selected.id}).then(response => {
            console.log(response.data)
            response.data.status? this.voter = response.data.record:this.voter={} 
            this.myClaims()
            this.myServices()
        }).catch(e => {
          console.log(e)
        })
      },
       ChangePassword: function() {
      
     },
    emitToParent (action) {
      this.$refs.form.reset()
      this.$emit('DialogEvent', {action: action, voter:this.voter})
    }

  }
}
</script>
