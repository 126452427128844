<template>

  <v-dialog
    v-model="show"
    persistent
    max-width="920">
    <v-card>
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-account-box</v-icon><span class="va-underline">{{state=='new'?'Open':'Edit'}} Branch </span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
        <v-card-text>
             <v-form
                ref="form"
                class="mt-4"
                v-model="valid"
                lazy-validation
              >
              <v-row>
                <v-col cols="6">
                  <v-text-field
                  outlined
                  dense
                  v-model="item.name"
                  label="Branch name"
                  :rules="textRules"
                  required
                  ></v-text-field>
                <v-autocomplete
                    v-model="item.franchisee_id"
                    :rules="textRules"
                    required
                    item-text="company"
                    item-value="id"
                    :disabled="!ALLOW('update_branch_franchisee')"
                    :items="franchiselist"
                    outlined
                    dense
                    label="Franchisee"
                  ></v-autocomplete> 
                    
                      <v-text-field
                        outlined
                        dense
                        v-model="item.permit_no"
                        label="Permit No."
                        :rules="textRules"
                        required
                        ></v-text-field>
                        <v-text-field
                    outlined
                    dense
                    v-model="item.tel_no"
                    label="Tel No."
                    :rules="textRules"
                    required
                    ></v-text-field>
                <v-autocomplete
                  v-model="item.status"
                   :rules="textRules"
                required
                  :items="STATUS"
                  outlined
                    dense
                  label="Status  "
                  ></v-autocomplete> 
                </v-col>
                <v-col cols="6">
                    
                    <v-text-field
                    outlined
                    dense
                    v-model="item.address"
                    label="Branch address"
                    :rules="textRules"
                    required
                    ></v-text-field>
                    <v-text-field
                    outlined
                    dense
                    v-model="latlong.lat"
                    label="Latitude"
                    :rules="textRules"
                    required
                    ></v-text-field>
                    <v-text-field
                    v-model="latlong.lng"
                    outlined
                    dense
                    label="Longitude"
                    ></v-text-field>
                    <v-row dense>
                      <v-toolbar dense>
                        POS License Keys:
                        <v-spacer/>
                        <v-btn x-small color="primary" @click="generateKey()" v-if="ALLOW('generate_branch_license')">Generate</v-btn>
                      </v-toolbar>
                      <table class="print_table mt-2">
                          <tr  v-for="(license, index) in licenses" :key="index+'-k'">
                            <td> {{license.key}} </td>
                            <!-- <td class="text-right"> <v-chip
                            small
                            class="ma-2"
                            :color="license.status == 'Available'?'info':'primary'"
                            text-color="white"
                            >
                           {{license.status}} 
                            </v-chip> </td> -->
                          </tr>
                      </table>
                      
                    </v-row>
                </v-col>
              </v-row>
             </v-form>
        </v-card-text>
        <v-card-actions>
                <v-btn color="warning" small rounded text @click="emitToParent('close')">CLOSE</v-btn>
            <v-spacer></v-spacer>
            <v-btn  small color="primary"  depressed v-if="ALLOW('update_branch')" @click="validate()">{{BTN_TEXT}}</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    },
    selectedID: {
       type: Number,
        default: null
    },
    state: {
        type: String,
        default: "new"
    }
  },
  data: () => ({
      valid: true,
      newpassword:'',
      show1: false,
      item: {},
      latlong:{},
      franchiselist:[],
      licenses:[],
      textRules: [
        v => !!v || 'Field is required'
      ],
  }),
  computed: {
      BTN_TEXT() {
          if(this.state == 'new')
            return "ADD"
         else 
            return "SAVE"
      },
      STATUS() {
        return ['New Application', 'Under-construction', 'Active', "Closed"]
      }
  },
  watch: {
    show (v) {
      if (v) {
        this.item = {}
        this.licenses = []
        if(this.state != 'new') { this.getItem() }
        this.getFranchisesList()
      } 
    }
  },
  methods: {
      generateKey() {
          var key = this.$randomString(4) + "-" + this.$paddingZeroLeft(this.selectedID, 5) +"-"+ this.$randomString(3)
          var newkey = {}
          newkey.key = key
          newkey.status="Available"
          this.licenses.push(newkey)
          this.item.licenses = this.licenses
          this.SaveKey()
      },
      validate () {
        if (this.$refs.form.validate()) {
            this.state=='new'?this.Add():this.Update()
        }
      },
      getItem: function() {
        this.$http.post("branch/get", {id:this.selectedID}).then(response => {
            response.data.status? this.item = response.data.item : this.item = {}
          this.latlong = this.item.latlong
          response.data.item.licenses != null ? this.licenses = response.data.item.licenses : this.licenses = []
        }).catch(e => {
          console.log(e)
        })
     },
       Add: function() {
          this.item.franchisee_id = this.$IsNum(this.item.franchisee_id)
            this.item.latlong = this.latlong
        this.$http.post("branch/new", this.item).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message) : this.VA_ALERT('error', response.data.message) 
            this.$emit('DialogEvent', "")
        }).catch(e => {
          console.log(e)
        })
     },
     SaveKey: function() {
         this.item.franchisee_id = this.$IsNum(this.item.franchisee_id)
            this.item.latlong = this.latlong
        this.$http.post("branch/update", this.item).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message) : this.VA_ALERT('error', response.data.message) 
         
        }).catch(e => {
          console.log(e)
        })
     },
      Update: function() {
         this.item.franchisee_id = this.$IsNum(this.item.franchisee_id)
            this.item.latlong = this.latlong
        this.$http.post("branch/update", this.item).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message) : this.VA_ALERT('error', response.data.message) 
            this.$emit('DialogEvent', "")
        }).catch(e => {
          console.log(e)
        })
     },
     getFranchisesList: function() {
        this.$http.get("franchisee/list").then(response => {
            response.data.status? this.franchiselist = response.data.list : this.franchiselist = {}
        }).catch(e => {
          console.log(e)
        })
     },
    emitToParent () {
        this.$emit('DialogEvent', "")
    }
  }
}
</script>
