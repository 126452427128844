<template>
    <v-dialog
    v-model="show"
    persistent
    fullscreen
    >
   
    <v-card color="light_gray">
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-cash</v-icon><span class="va-underline">Household Members</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text class="pa-4 def-font">
      
             <div class="pa-2 font-weight-bold">{{person.surname}},  {{person.first_name}} {{person.middle_name}}
            <br/><span class="font-weight-thin">({{person.position}})</span>
            </div>
             <v-divider/>
             <template v-for="(item, index) in members">
             <div :key="index" class="pa-2">
                {{item.surname}},  {{item.first_name}} {{item.middle_name}}
                <br/><span class="font-weight-thin">({{item.position}})</span>
                <v-divider/>
             </div>
             
             </template>
             <v-btn class="mt-4" color="success" block @click="$router.push('/data-sheet/'+person.id).catch(err => {})">View Data</v-btn>
          </v-card-text>
        
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
      show:{
          type: Boolean,
          default: false
      },
      person: {
          type: Object,
          default: ()=>{return {}}
      },
      members: {
          type: Object,
          default: ()=>{return []}
      }  
  },
  data () {
      return {
          valid: true,
          entry:{},
           nameRules: [
            v => !!v || '*Required'
        ],
      }
    },
  watch: {
    //  show(val) {
        
    //  }
  },
  computed:{
   
  },
  methods: {
   emitToParent (action) {
      this.$emit('DialogEvent',action)
    },
    
  }
}
</script>
