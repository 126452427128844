<template>
    <v-navigation-drawer
    class="noprint"
      v-model="$store.state.drawer"
      absolute
      dark
      id="va-drawer"
      color="primary"
    >
      <v-list-item>
        <v-list-item-avatar>
          <v-img :src="$store.state.applogo"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>ADMIN</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      
      <v-list dense>
      <v-list-item-group color="fff">
          <v-list-item  @click="$router.push('/dashboard').catch(err => {})">
          <v-list-item-icon>
            <v-icon small>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="mid-font">DASHBOARD</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
          <!-- <v-list-item  @click="$router.push('/bets').catch(err => {})">
          <v-list-item-icon>
            <v-icon small>mdi-database-arrow-up</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="mid-font">Bets</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
         <v-list-item  @click="$router.push('/top-players').catch(err => {})">
          <v-list-item-icon>
            <v-icon small>mdi-seal</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="mid-font">Top Players</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
       <!-- <template v-if="ALLOWED_SET_UP"> -->
          <!-- <v-subheader class="mid-font">OPERATIONS</v-subheader>
        <v-list-item
          v-for="(item, i) in operations"
         :key="i + '-operation'"
          @click="$router.push(item.page).catch(err => {})"
        >
          <v-list-item-icon>
            <v-icon small v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" class="mid-font"></v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-subheader class="mid-font">ADMINISTRATION</v-subheader>
        <v-list-item
          v-for="(item, i) in MENUS"
         :key="i + '-admin'"
          @click="$router.push(item.page).catch(err => {})"
        >
          <v-list-item-icon>
            <v-icon small v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" class="mid-font"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
       <!-- </template> -->
      
      </v-list-item-group>
    </v-list>


    </v-navigation-drawer>

</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data () {
      return {
        item: 1,
        // operations: [
        //   { title: 'FIGHT ENTRIES', icon: 'mdi-clipboard-list', page:'/users'},
        //   { title: 'BREEDERS', icon: 'mdi-account-group', page:'/game-settings'},
        //   { title: 'SETTINGS', icon: 'mdi-cogs', page:'/game-settings'}
        // ],
        bhw: [
           
          // { title: 'USERS', icon: 'mdi-account-key', page:'/users'},
           { title: 'CENSUS', icon: 'mdi-account-file-text', page:'/census'},
          // { title: 'FINDER', icon: 'mdi-account-search', page:'/finder'},
          // { title: 'HOUSEHOLDS', icon: 'mdi-human-male-male-child', page:'/households'},
           { title: 'SCAN', icon: 'mdi-barcode-scan', page:'/scanner'},
          //  { title: 'VOTERS', icon: 'mdi-account-multiple', page:'/voters'},
          //  { title: 'SCANNER', icon: 'mdi-qrcode-scan', page:'/scanner'},
          //  { title: 'PER EVENTS', icon: 'mdi-account-multiple', page:'/per-event'},
          //  { title: 'PER Distributor', icon: 'mdi-account-circle', page:'/per-user'},
          //  { title: 'EVENTS', icon: 'mdi-calendar-star', page:'/events'},
        ],
        super: [
           
           { title: 'USERS', icon: 'mdi-account-key', page:'/users'},
           { title: 'CENSUS', icon: 'mdi-account-file-text', page:'/census'},
           { title: 'FINDER', icon: 'mdi-account-search', page:'/finder'},
           { title: 'HOUSEHOLDS', icon: 'mdi-human-male-male-child', page:'/households'},
           { title: 'SCAN', icon: 'mdi-barcode-scan', page:'/scanner'},
          //  { title: 'VOTERS', icon: 'mdi-account-multiple', page:'/voters'},
          //  { title: 'SCANNER', icon: 'mdi-qrcode-scan', page:'/scanner'},
          //  { title: 'PER EVENTS', icon: 'mdi-account-multiple', page:'/per-event'},
          //  { title: 'PER Distributor', icon: 'mdi-account-circle', page:'/per-user'},
          //  { title: 'EVENTS', icon: 'mdi-calendar-star', page:'/events'},
        ],
        admin: [
           
        
           { title: 'CENSUS', icon: 'mdi-account-file-text', page:'/census'},
           { title: 'FINDER', icon: 'mdi-account-search', page:'/finder'},
           { title: 'HOUSEHOLDS', icon: 'mdi-human-male-male-child', page:'/households'},
           { title: 'SCAN', icon: 'mdi-barcode-scan', page:'/scanner'},
            { title: 'VOTERS', icon: 'mdi-account-multiple', page:'/voters'},
            { title: 'CLAIM', icon: 'mdi-qrcode-scan', page:'/claims'},
           { title: 'PER EVENTS', icon: 'mdi-account-multiple', page:'/per-event'},
           { title: 'PER Distributor', icon: 'mdi-account-circle', page:'/per-user'},
           { title: 'EVENTS', icon: 'mdi-calendar-star', page:'/events'},
           { title: 'Com. Services', icon: 'mdi-hand-heart', page:'/services'},
           { title: 'USERS', icon: 'mdi-account-key', page:'/users'},
        ],
        operation: [
        { title: 'CENSUS', icon: 'mdi-account-file-text', page:'/census'},
        { title: 'FINDER', icon: 'mdi-account-search', page:'/finder'},
        { title: 'HOUSEHOLDS', icon: 'mdi-human-male-male-child', page:'/households'},
        { title: 'SCAN', icon: 'mdi-barcode-scan', page:'/scanner'},
        //{ title: 'USERS', icon: 'mdi-account-key', page:'/users'},
        // { title: 'VOTERS', icon: 'mdi-account-multiple', page:'/voters'},
        //{ title: 'VOUCHERS', icon: 'mdi-qrcode', page:'/vouchers'},
        { title: 'CLAIM', icon: 'mdi-qrcode-scan', page:'/claims'},
        //{ title: 'UNCLAIMS',icon: 'mdi-ticket-confirmation', page:'/claim-summary'},
        // { title: 'BREEDERS', icon: 'mdi-account-group', page:'/breeders'},
        //  { title: 'OWNERS', icon: 'mdi-account-tie', page:'/owners'},
        // { title: 'ACCOUNTS', icon: 'mdi-account-key', page:'/users'},
        { title: 'Com. Services', icon: 'mdi-cogs', page:'/game-settings'}
        ],
        helpdesk: [
        { title: 'FINDER', icon: 'mdi-account-search', page:'/finder'},
        ],
         reports: [
         // { title: 'Cash Flow', icon: 'mdi-chart-tree', page:'/cash-reports'},
          { title: 'Fights Summary', icon: 'mdi-database-export-outline', page:'/fight-summary'},
          { title: 'BETS ', icon: 'mdi-ticket-confirmation', page:'/bet-summary'},
          { title: 'UNCLAIMS',icon: 'mdi-ticket-confirmation', page:'/claim-summary'},
          { title: 'POS ', icon: 'mdi-cash-register', page:'/event-summary'}
        ],
        mini: true,
      }
    },
    computed: {
      ALLOWED_SET_UP() {
           if(this.$session.get('role') == "Administrator") {
              return true
           }
           return false
      },
      MENUS() {
        if(this.$session.get('role') == "Administrator") {
              return this.admin
           } else if(this.$session.get('role') == "Help Desk")  {
              return this.helpdesk
           }   else if(this.$session.get('role') == "Super Admin")  {
              return this.super
           }   else if(this.$session.get('role') == "BHW")  {
              return this.bhw
           } else {
            return this.operation
           }
          
      }
    },
    methods: {
      ...mapMutations(['setDrawer']),
    }
  }
</script>