<template>
    <div v-if="show">
   
    <v-card dense>
        <v-toolbar>
           <v-icon left color="info">mdi-account-cash-outline</v-icon> CASH OUT
            <v-spacer/>
              <span style="width: 200px; margin-top: 24px;"> 
                     <v-menu
                        v-model="from_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="From Date"
                                 :value="from_date"
                                 filled
                                 readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="from_date" no-title scrollable @input="from_menu=false" />
                    </v-menu>
               </span>
                <span style="width: 200px; margin-top: 24px;" class="ml-2"> 
                     <v-menu
                        v-model="to_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="To Date"
                                 :value="to_date"
                                 readonly
                                 filled
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="to_date" no-title scrollable @input="to_menu=false, qryTransactions()" />
                    </v-menu>
               </span>
        </v-toolbar>
        <v-card-text>
           <table class="va-table def-font" id="over-table">
                <tr>
                    <th>Trans.</th>
                    <th>Trans Dt.</th>
                    <th class="text-right">AMOUNT</th>
                </tr>
                 <tr v-for="(item,index) in loads" :key="index" >
                    <td class="border_bot">{{item.type}} </td>
                    <td class="border_bot">{{$moment(item.transdt).format("YYYY-MM-DD HH:mm:ss A") }} </td>
                    <td class="text-right border_bot">{{$money(item.amount)}}</td>
                  
                </tr>
                <tr>
                    <th colspan="2" class="text-right"  style="background: #efefef;">
                        Date Covered {{$moment(from_date).format("YYYY-MM-DD") }} to {{$moment(to_date).format("YYYY-MM-DD") }} total loads : 
                    </th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALLOADS)}}</th>
                </tr>
               
            </table>
        </v-card-text>     
    </v-card>
  </div>
</template>

<script>

export default {
  props: {
      show:{
          type: Boolean,
          default: false
      }
  },
  data: () => ({
        loading: false,
        to_menu: false,
        from_menu: false,
        from_date:null,
        to_date:null,
        loads:[]
    }),
    created() {
         this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
         this.from_date = this.$moment().subtract(7,'days').format("YYYY-MM-DD")
         this.to_date = this.$moment().format("YYYY-MM-DD")
          this.qryTransactions()
    },
    computed:{
        TOTALLOADS() {
            var total = this.loads.reduce((res,item)=>res += this.$IsNum(item.amount),0)
            return total
        }
    },
  methods: {
       qryTransactions(){
          console.log({qry_str:"Add Load", from_date:this.from_date, to_date:this.to_date})
           this.loads = []
           this.$http.post("report/qry_transactions", {qry_str:"Cash Out", from_date:this.from_date, to_date:this.to_date}).then(response => {
            response.data.items != null?this.loads = response.data.items:this.loads =[]
            console.log(this.loads)
          }).catch(e => {
            console.log(e.data)
          })
       },
        dlToCsv(){
            this.DownloadToCsv("acccount-table", 'Per-account-' + this.from_date + '-to-' + this.to_date)
        }
  }
}
</script>
