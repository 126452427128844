<template>

  <v-dialog
    v-model="show"
    persistent
    max-width="720">
    <v-card>
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-fencing</v-icon><span class="va-underline">{{state=='new'?'New':'Edit'}} Fight</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
        <v-card-text class="pa-1">
             <v-form
                ref="form"
                class="mt-4"
                autocomplete="off"
                v-model="valid"
                lazy-validation
              >
              <v-row>
                 <v-col cols="12" class="text-h4 font-weight-bold text-center"> 
                      NUMBER OF FIGHTS: <br/>
                      <input  style="border: 1px solid #555; text-align: center; padding: 4px; width: 150px;" type="number" required v-model="fight.fight_no"  :rules="textRules"/>
                    
                </v-col>
                
              </v-row>
             </v-form>
        </v-card-text>
        <v-card-actions>
                <v-btn color="warning" small rounded text @click="emitToParent('close')">CLOSE</v-btn>
            <v-spacer></v-spacer>
            <v-btn   color="success" large  @click="validate()">{{BTN_TEXT}}</v-btn>
        </v-card-actions>
    </v-card>
  
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    },
    entry: {
      type:Object,
      default:()=>{}
    },
  
    state: {
        type: String,
        default: "new"
    }
  },
  data: () => ({
      valid: true,
      confirm: false,
      fight:{},
      meron_breeder:{meta:{}},
      wala_breeder:{meta:{}},
      meron:{},
      wala:{},
      select_result: '',
      textRules: [
        v => !!v || 'Field is required'
      ],
  }),
  computed: {
      BTN_TEXT() {
          if(this.state == 'new')
            return "READY"
         else 
            return "SAVE"
      },
      BREEDERS() {
        return this.$store.state.breeders
      },
      OWNERS() {
        return this.$store.state.owners
      }
  },
  watch: {
    show(v) {
      if(v){
        if(this.state == 'new') {
          this.fight={status:"Ready"}
          this.meron={}
          this.wala = {}
          this.meron_breeder={meta:{}}
          this.wala_breeder={meta:{}}
         
          
        } else {
          this.getFight()
        
        }
      } 
    }
  },
  methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.emitToParent(this.state)
        }
      },
       getFight(){
         console.log("getFight")
          this.$http.post("fight/get", {id: this.entry.id}).then(response => {
            response.data.status? this.fight = response.data.item:this.fight={} 
              this.meron= this.fight.meron
          this.wala =  this.fight.wala
            this.meron_breeder= this.fight.meron_breeder
          this.wala_breeder =  this.fight.wala_breeder
        }).catch(e => {
          console.log(e)
        })
      },
    emitToParent (action) {
        this.fight.meron = this.meron
        this.fight.wala = this.wala
        this.fight.meron_breeder = this.meron_breeder
        this.fight.wala_breeder = this.wala_breeder
        this.$emit('DialogEvent', {action: action, fight:this.fight})
    }

  }
}
</script>
