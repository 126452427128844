<template>
    <v-dialog
    v-model="show"
    persistent
    width="500">
   
    <v-card color="light_gray">
      
          <v-card-text class="pa-4 def-font">
           
              <div class="text-h5 text-center text-info">Saving Data. Please wait...!</div>
          </v-card-text>
        
    </v-card>
  </v-dialog>
</template>

<script>
import { API_MIXINS } from '@/mixins/api_mixins.js'
export default {
    mixins: [API_MIXINS],
  props: {
      show:{
          type: Boolean,
          default: false
      },
      result: {
           type: String,
          default: ''
      },
      header: {
           type: String,
          default: ''
      }
  },
   data () {
      return {
          textval: '',
          valid: true,
           nameRules: [
            v => !!v || '*Required'
        ],
      }
    },
  watch: {
      show(val) {
          if(!val) {
              this.textval = ''
          }
      }
  },
  computed:{
  },
  methods: {
  
  }
}
</script>
