<template>

  <v-dialog
    v-model="show"
    persistent
    max-width="720">
    <v-card>
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-wallet</v-icon><span class="va-underline">{{state=='new'?'New':'Update'}} Request </span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
        <v-card-text>
             <v-form
                ref="form"
                class="mt-4"
                v-model="valid"
                lazy-validation
              >
              <v-row>
                <v-col cols="12">
                   <v-autocomplete
                  v-model="item.franchisee_id"
                  item-text="company"
                  item-value="id"
                   :rules="textRules"
                   :disabled="true"
                required
                  :items="$store.state.franchiseelist"
                  outlined
                    dense
                  label="Select Franchise"
                  ></v-autocomplete> 
                  <v-autocomplete
                  v-model="item.type"
                   :rules="textRules"
                required
                :disabled="DISABLED"
                  :items="['Top Up', 'Refund']"
                  outlined
                    dense
                  label="Type"
                  ></v-autocomplete> 
                   <v-text-field
                  outlined
                  dense
                  type="number"
                  v-model="item.amount"
                  :disabled="DISABLED"
                  label="Enter Request Amount"
                  :rules="textRules"
                  required
                  ></v-text-field>
                   <v-textarea
                  outlined
                  dense
                  v-model="item.note"
                   :disabled="DISABLED"
                  label="Additional Note"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row class="text-center" v-if="state!='new'">
                 <v-col cols="4" v-if="item.requested_by">
                   <p class="va-underline"><img :src="item.requested_by.signature" width="100%"/></p>
                   {{ item.requested_by.fullname}} <br /><small>Requested: {{item.req_dt | moment("MMM DD, YYYY")}}</small>
                 </v-col>
                   <v-col cols="4" v-if="item.verified_by &&  item.verified_by.user_id">
                   <p class="va-underline"><img :src="item.verified_by.signature" width="100%"/></p>
                   {{ item.verified_by.fullname}} <br /><small>Verified: {{item.ver_dt | moment("MMM DD, YYYY")}}</small>
                 </v-col>
                   <v-col cols="4" v-if="item.approved_by && item.approved_by.user_id">
                   <p class="va-underline"><img :src="item.approved_by.signature" width="100%"/></p>
                   {{ item.approved_by.fullname}} <br /><small>Approved: {{item.ap_dt | moment("MMM DD, YYYY")}}</small>
                 </v-col>
              </v-row>
             </v-form>
        </v-card-text>
        <v-card-actions>
                STATUS: <span class="ml-2" :class="STATUS_COLOR(item.status)">{{item.status}}</span>
                <template v-if="item.status == 'Complete'">
                  <small class="ml-2">( {{item.load_dt | moment("MMM DD, YYYY")}})</small>
                </template>
            <v-spacer></v-spacer>
             <v-btn  small color="error"  depressed v-if="CANCELBTN" @click="set_cancel()">CANCEL</v-btn>
              <v-btn  small color="success"  depressed v-if="state=='new' && ALLOW('wallet_request_top_up')" @click="validate()">Request</v-btn>
              <v-btn  small color="success"  depressed  v-if="item.status=='Requested' && ALLOW('wallet_verify_top_up')" @click="item.status='Verified',validate()">Verify</v-btn>
              <v-btn  small color="success"  depressed v-if="item.status=='Verified' && ALLOW('wallet_approve_top_up')" @click="item.status='Approved',validate()">Approved</v-btn>
              <v-btn  small color="success"  depressed v-if="item.status=='Approved' && ALLOW('wallet_complete_top_up')" @click="item.status='Complete', set_complete()">Complete Request</v-btn>
        </v-card-actions>
    </v-card>
    <va-signature-pad :show="pad" @DialogEvent="padEvent" />
    <va-confirm :show="confirm" :message="confirmMsg" :okbtn="confirmOkbtn" :cancelbtn="confirmCancelbtn" @ConfirmEvent="cEvent"/>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    },
    selectedID: {
       type: Number,
        default: null
    },
    state: {
        type: String,
        default: "new"
    }
  },
  data: () => ({
      valid: true,
      todo:null,
      confirmMsg: "",
      confirmOkbtn: "",
      confirmCancelbtn: "", 
      confirm: false,
      newpassword:'',
      pad: false,
      show1: false,
      item: {},
      set_status:"Requested",
      account:{},
      latlong:{},
      signature:null,
      franchiselist:[],
      textRules: [
        v => !!v || 'Field is required'
      ],
  }),
  computed: {
     
       CANCELBTN() {
       if(this.item.status == "Requested" || this.item.status == "Verified") {
          return true
       } 
       return false
      },
      DISABLED() {
       if(this.item.status == "Approved" || this.item.status == "Complete") {
          return true
       } 
       return false
      },
      ROLES() {
        return ['New Application', 'Under-construction', 'Active', "Closed"]
      }
  },
  watch: {
    show (v) {
      if (v) {
        this.set_status = "Requested"
        this.item = {requested_by:{}}
         var account = this.$session.get("account")
        this.item.franchisee_id = this.$IsNum(account.franchisee_id)
        if(this.state != "new") {
          this.item.requested_by.signature = ''
           this.item.requested_by.fullname = ''
            this.item.requested_by.user_id = 0
            this.getItem()
        }
      } 
    }
  },
  methods: {
    set_cancel() {
      this.confirmMsg = "Would you like to cancel this request?"
      this.confirmOkbtn ="CANCEL Request"
      this.confirmCancelbtn ="No"
      this.todo = "cancel_request"
      this.confirm = true
    },
    set_complete() {
      this.confirmMsg = "Are you sure to complete this request?"
      this.confirmOkbtn ="Complete"
      this.confirmCancelbtn ="Not Now"
      this.todo = "complete_request"
      this.confirm = true
    },
    cEvent(data) {
      this.confirm = false
      if(data == "ok") {
        if(this.todo == "cancel_request") {
          this.item.status = "Cancelled"
          this.SaveRequest()
        } else if(this.todo == "complete_request") {
            this.item.status = "Complete"
             this.account.user_id = parseInt( this.$session.get('userID'))
              this.account.fullname = this.$session.get('fullname')
            this.setUpdate()
            this.SaveRequest()
        }
      }
    },
    padEvent(data){
       this.pad = false
      if(data != "close") {
            this.account.user_id = parseInt( this.$session.get('userID'))
              this.account.fullname = this.$session.get('fullname')
              this.account.signature =  data
            if(this.state =="new") {
              
              this.item.requested_by = this.account
              this.item.status = this.set_status
              this.SendRequest()
            } else {
              this.setUpdate()
              this.SaveRequest()
            }

      }
    },
    setUpdate() {
      if(this.item.status == "Verified") {
              this.item.verified_by = this.account
      } else if(this.item.status == "Approved") {
           this.item.approved_by = this.account
      } else if(this.item.status == "Complete") {
           this.item.load_by = this.account
      }
    },
    getItem(){
        this.$http.post("wallet/get", {id:this.selectedID}).then(response => {
            response.data.status? this.item = response.data.item:this.item = {}
        }).catch(e => {
          console.log(e)
        })
    },
    Complete () {
        if (this.$refs.form.validate()) {
            this.pad = true
        }
      },
      validate () {
        if (this.$refs.form.validate()) {
            //this.state=='new'?this.Add():this.Update()
            this.pad = true
        }
      },
     
      SendRequest: function() {
        this.item.amount = this.$IsNum(this.item.amount)
        this.$http.post("wallet/new", this.item).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message) : this.VA_ALERT('error', response.data.message) 
            this.$emit('DialogEvent', "")
        }).catch(e => {
          console.log(e)
        })
     },

       SaveRequest: function() {
        this.item.amount = this.$IsNum(this.item.amount)
        this.$http.post("wallet/update", this.item).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message) : this.VA_ALERT('error', response.data.message) 
            this.$emit('DialogEvent', "")
        }).catch(e => {
          console.log(e)
        })
     },
    emitToParent () {
        this.$emit('DialogEvent', "")
    }
  }
}
</script>
