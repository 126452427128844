import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: "#0E1629",//'#AC1F62',
            secondary:  '#3C3C3C',
            tertiary: '#495057',
            accent: '#713BDB',
            error: '#f55a4e',
            info: '#00d3ee',
            success: "#5cb860",//#5cb860',
            warning: '#ffa21a',
            wala: '#281BCA',
            meron: '#C61212',
            cancelled: '#757575',
            draw: '#5cb860'
          },
        },
      }
});
