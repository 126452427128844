<template>
    <div v-if="show">
   
    <v-card dense>
        <v-toolbar>
             COORDINATOR  DAILY SUMMARY
            <v-spacer/>
            <span style="width: 270px; margin-top: 24px; margin-right: 7px;"> 
                    <v-autocomplete
                        :items="coordinators"
                        label="Select Coordinator"
                        item-text="fullname"
                          v-model="coordinator"
                        return-object
                        @change="getTransactions()"
                         dense
                         outlined
                    />
               </span>
               <span style="width: 200px; margin-top: 24px;"> 
                     <v-menu
                        v-model="from_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="Select Date"
                                 :value="from_date"
                                 filled
                                 readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="from_date" no-title scrollable @input="from_menu=false" />
                    </v-menu>
               </span>
                
               <v-icon right color="primary" @click="dlToCsv()">mdi-download</v-icon>
        </v-toolbar>
        <v-card-text>
             <v-progress-linear
                v-if="loading"
                indeterminate
                color="teal"
                ></v-progress-linear>
             <p class="text-center">
               COORDINATOR  DAILY SUMMARY<br />
                Date Covered: {{$moment(from_date).format('YYYY-MMM-DD')}} 
            </p>
            <table class="va-table def-font" id="daily_coordinator_summary">
                <tr>
                    <th colspan="8">COORDINATOR: {{coordinator.fullname}}</th>
                   
                </tr>
                <tr>
                    <th>AGENT</th>
                    <th class="text-right">GROSS</th>
                    <th class="text-right">NET</th>
                    <th class="text-right">HITS</th>
                    <th class="text-right">CLAIMED HITS</th>
                    <th class="text-right">UNCLAIMED HITS</th>
                    <th class="text-right">REVENUE</th>
                </tr>
                <tr v-for="(item,index) in items" :key="index">
                    <td>{{item.username}}</td>
                    <td class="text-right">{{$money(item.gross)}}</td>
                    <td class="text-right">{{$money(item.gross - (item.gross * (item.commission/100)))}}</td>
                    <td class="text-right">{{$money(item.win_amount)}}</td>
                    <td class="text-right">{{$money(item.claimed_amount)}}</td>
                     <td class="text-right">{{$money(item.win_amount - item.claimed_amount)}}</td>
                    <td class="text-right">{{$money(item.gross - (item.gross * (item.commission/100)) - item.win_amount)}}</td>
                </tr>
                 
            </table>
        </v-card-text>     
    </v-card>
  </div>
</template>

<script>

export default {
  props: {
      show:{
          type: Boolean,
          default: false
      }
  },
  data: () => ({
        loading: false,
        from_menu: false,
        to_menu: false,
        from_date: '',
        to_date: '',
        items:[],
        coordinators:[],
        coordinator:{}
       
    }),
    watch: {
        show(v){
            if(v) {
                 this.getCoordinators()
            }
        }
    },
    computed:{
        // COORS() {
        //     var total = this.items.reduce((res,item)=>res += this.$IsNum(item.gross),0)
        //     return this.coordinators
        // },
        //  TOTALHITS() {
        //     var total = this.items.reduce((res,item)=>res += this.$IsNum(item.amount_of_winning),0)
        //     return total
        // },
        //  TOTALPAYOUT() {
        //     var total = this.items.reduce((res,item)=>res += this.$IsNum(item.payout),0)
        //     return total
        // }
    },
  methods: {
      getCoordinators(){
            this.loading = true
            this.coodinators =[]
             this.$http.post("user/get_coordinator").then(response => {
                response.data.coordinators !=null?this.coordinators = response.data.coordinators:this.coordinators =[]
               console.log(this.coordinators)
               this.loading = false
          }).catch(e => {
            console.log(e.data)
          })
        },
        getTransactions(){
            this.loading = true
            this.items =[]
            var param = {}
            param.from_date = this.from_date
            param.account = this.coordinator.id
            param.Method = "daily_coordinator_summary"
             this.$http.post("report/transactions",param).then(response => {
                response.data.items !=null?this.items = response.data.items:this.items =[]
                console.log(response.data.items)
                this.loading = false
          }).catch(e => {
            console.log(e.data)
          })
        },
        dlToCsv(){
            this.DownloadToCsv("daily_coordinator_summary", 'Daily-coordinator-summary-' + this.from_date)
        }
  }
}
</script>
