<template>

  <v-dialog
    v-model="show"
    persistent
    fullscreen
    >
    <v-card>
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-account-box</v-icon><span class="va-underline">{{state=='new'?'New':'Edit'}} Data</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
        <v-card-text>
             <v-form
                ref="form"
                class="mt-8"
                v-model="valid"
                lazy-validation
              >
              <v-row>
                <v-col cols="6" sm="6">
                  <v-select
                v-model="item.gender"
                dense
                label="Gender"
                :items="['Male', 'Female']"
              />
                </v-col>
                <v-col cols="6" sm="6">
                  <v-select
                v-model="item.civil_status"
                dense
                label="Civil Status"
                :items="['Married', 'Widow','Single']"
              />
                </v-col>
              </v-row>
              
              <v-text-field  
              v-model="item.surname"
              dense
              label="Surname"/>
              <v-text-field  
              v-model="item.firstname"
              dense
              label="First Name"/>
              <v-text-field  
              v-model="item.middlename"
              dense
              label="Middle Name"/>

              <v-text-field  
              dense
              label="Birth Day"/>

           <v-row>
            <v-col cols="12" sm="6">
           
                <v-autocomplete
                v-model="item.region"
              dense
                label="Region "
                :items="regions"
                      item-value="name"
                      item-text="name"
                      @change="setProvinces()"
              />
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
              dense
              v-model="item.province"
                label="Province "
                :items="provinces"
                item-value="name"
                      item-text="name"
                      @change="setMunicipalities()"
              />
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                v-model="item.municipality"
              dense
                label="Municipality "
                :items="municipalities"
              />
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                v-model="item.barangay"
              dense
                label="Barangay "
                :items="$store.state.occupations"
              />
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field  
              v-model="item.address"
              dense
              label="Address"/>
              </v-col>
           </v-row>

              <v-checkbox  
              dense
              v-model="item.pwd"
              label="PWD"/>

              <v-checkbox  
              dense
              v-model="item.senior"
              label="Senior Citizen"/>

              <v-checkbox  
              v-model="item.student"
              dense
              label="Student"/>

              <v-autocomplete
              v-model="item.occupation"
              dense
                label="Occupation "
                :items="$store.state.occupations"
              />

             </v-form>
        </v-card-text>
        <v-card-actions>
                <v-btn color="warning" small rounded text @click="emitToParent('close')">CLOSE</v-btn>
            <v-spacer></v-spacer>
            <v-btn  small color="success"  depressed  @click="validate()" >{{BTN_TEXT}}</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    },
    selectedID: {
       type: Number,
        default: null
    },
    state: {
        type: String,
        default: "new"
    }
  },
  data: () => ({
      valid: true,
      newpassword:'',
      show1: false,
      item: {},
      regions: [],
      provinces: [],
      municipalities: [],
      barangays:[],
      textRules: [
        v => !!v || 'Field is required'
      ],
  }),
  computed: {
      BTN_TEXT() {
          if(this.state == 'new')
            return "ADD"
         else 
            return "SAVE"
      },
  },
  watch: {
    show (v) {
      if (v) {
        this.initRegions()
      } 
    }
  },
  methods: {
      validate () {
        if (this.$refs.form.validate()) {
            this.state=='new'?this.Add():this.Update()
        }
      },
      setProvinces() {
      var obj = this.$store.state.Philippines;
      Object.values(obj).forEach((val) => {
        if (this.item.region == val.region_name) {
          this.provinces = Object.keys(val.province_list);
        }
      });
    },
    setMunicipalities() {
      var obj = this.$store.state.Philippines;
      Object.values(obj).forEach((val) => {
        if (this.item.region == val.region_name) {
          Object.entries(val.province_list).forEach(([key, value]) => {
            if (key == this.item.province) {
              this.municipalities = Object.keys(value.municipality_list);
            }
          });
        }
      });
    },
    setBarangays() {
      var obj = this.$store.state.Philippines;
      Object.values(obj).forEach((val) => {
        if (this.item.region == val.region_name) {
          Object.entries(val.province_list).forEach(([key, value]) => {
            if (key == this.item.province) {
              this.municipalities = Object.keys(value.municipality_list);
            }
          });
        }
      });
    },
      initRegions() {
      var items = []
      var entries = Object.entries(this.$store.state.Philippines);
      entries.forEach(([key, value]) => {
        items.push({key:key, name:value.region_name});
      });
      this.regions = items
    },
    emitToParent () {
        this.$emit('DialogEvent', "")
    }
  }
}
</script>
