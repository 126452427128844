<template>

  <v-dialog
    v-model="show"
    persistent
    max-width="600">
    <v-card>
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-account</v-icon><span class="va-underline">{{state=='new'?'Register':'Edit'}} Owner</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
        <v-card-text>
             <v-form
                ref="form"
                class="mt-4"
                v-model="valid"
                lazy-validation
              >
              <v-row>
                <v-col cols="12">
                  
               <v-text-field
                 v-model="owner.name"
                  label="Owner Name *"
                  outlined
                  dense
                  :rules="textRules"
                required
                ></v-text-field>   
                 <v-text-field
                 outlined
                  dense
                 v-model="owner.address"
                  label="Address"
                ></v-text-field>  

                 <v-text-field
                 outlined
                  dense
                 v-model="owner.contact_no"
                  label="Contact No."
                ></v-text-field>                      
                 
                </v-col>
              
              </v-row>
             </v-form>
        </v-card-text>
        <v-card-actions>
                <v-btn color="warning" small rounded text @click="emitToParent('close')">CLOSE</v-btn>
            <v-spacer></v-spacer>
            <v-btn  small color="success"  depressed  @click="validate()">{{BTN_TEXT}}</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    },
    owner_id: {
        type: Number,
        default:null
    },
    state: {
        type: String,
        default: "new"
    }
  },
  data: () => ({
      valid: true,
      newpassword:'',
      owner:{},
      meta:{},
      show1: false,
      textRules: [
        v => !!v || 'Field is required'
      ],
  }),
  computed: {
      BTN_TEXT() {
          if(this.state == 'new')
            return "ADD"
         else 
            return "SAVE"
      }
  },
  watch: {
    show (v) {
      if(v) {
         if (this.state != 'new') {
          this.getowner()
        } else {
          this.meta = {}
          this.owner = {}
        }
      }
     
    }
  },
  methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.emitToParent(this.state)
          this.show1 = false
        }
      },
      getowner(){
          this.$http.post("owner/get", {id: this.owner_id}).then(response => {
            response.data.status? this.owner = response.data.owner:this.owner={} 
            this.meta = this.owner.meta
        }).catch(e => {
          console.log(e)
        })
      },
    emitToParent (action) {
      //this.owner.meta = this.meta
      this.$emit('DialogEvent', {action: action, owner:this.owner})
    }

  }
}
</script>
