<template>
    <v-dialog
    v-model="show"
    persistent
    width="400">
   
    <v-card color="light_gray">
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-gamepad-circle-up</v-icon><span class="va-underline">{{game}} KEY-IN FORM</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text class="pa-4 def-font">
               <v-form
                ref="form"
                v-model="valid"
                lazy-validation>
                    <v-row>
                        
                        <v-col cols="12">
                             <v-text-field
                             type="number"
                                v-model="draw_id"
                                label="Enter Draw ID"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>

                        <!-- PICK 3 -->
                         <template v-if="game == 'PICK3'">
                        <v-col cols="4">
                             <v-text-field
                             type="number"
                                v-model="Result.num1"
                                label="Num1"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                         <v-col cols="4">
                             <v-text-field
                             type="number"
                                v-model="Result.num2"
                                label="Num2"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                         <v-col cols="4">
                             <v-text-field
                             type="number"
                                v-model="Result.num3"
                                label="Num3"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                         <v-col cols="4">
                             <v-text-field
                             type="number"
                                v-model="Result.num4"
                                label="Num4"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                         <v-col cols="4">
                             <v-text-field
                             type="number"
                                v-model="Result.num5"
                                label="Num5"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                         <v-col cols="4">
                             <v-text-field
                             type="number"
                                v-model="Result.num6"
                                label="Num6"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                        
                        </template>
                        <!-- 2d -->
                        <template v-if="game == '2D'">
                        <v-col cols="6">
                             <v-text-field
                             type="number"
                                v-model="Result.num1"
                                label="Num1"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                         <v-col cols="6">
                             <v-text-field
                             type="number"
                                v-model="Result.num2"
                                label="Num2"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                        
                        </template>
                         <!-- SWER3 -->
                        <template v-if="game == 'SWER3'">
                        <v-col cols="4">
                             <v-text-field
                             type="number"
                                v-model="Result.num1"
                                label="Num1"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                         <v-col cols="4">
                             <v-text-field
                             type="number"
                                v-model="Result.num2"
                                label="Num2"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                         <v-col cols="4">
                             <v-text-field
                             type="number"
                                 v-model="Result.num3"
                                label="Num3"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                        </template>
                            <!-- 3DLOCAL -->
                         <template v-if="game == '3DLOCAL'">
                        <v-col cols="4">
                             <v-text-field
                             type="number"
                                v-model="Result.num1"
                                label="Num1"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                         <v-col cols="4">
                             <v-text-field
                             type="number"
                                v-model="Result.num2"
                                label="Num2"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                         <v-col cols="4">
                             <v-text-field
                             type="number"
                                 v-model="Result.num3"
                                label="Num3"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                        </template>
                             <!-- 2DLOCAL -->
                         <template v-if="game == '2DLOCAL'">
                        <v-col cols="6">
                             <v-text-field
                             type="number"
                                v-model="Result.num1"
                                label="Num1"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                         <v-col cols="6">
                             <v-text-field
                             type="number"
                                v-model="Result.num2"
                                label="Num2"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                        </template>
                             <!-- 2WER2 -->
                        <template v-if="game == 'SWER2' || game == 'STLPARES' ||game == 'STLLOCAL'">
                        <v-col cols="6">
                             <v-text-field
                             type="number"
                                v-model="Result.num1"
                                label="Num1"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                         <v-col cols="6">
                             <v-text-field
                             type="number"
                                v-model="Result.num2"
                                label="Num2"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                        
                        </template>
                         <!-- 3D -->
                        <template v-if="game == '3D'">
                        <v-col cols="4">
                             <v-text-field
                             type="number"
                                v-model="Result.num1"
                                label="Num1"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                         <v-col cols="4">
                             <v-text-field
                             type="number"
                                v-model="Result.num2"
                                label="Num2"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                         <v-col cols="4">
                             <v-text-field
                             type="number"
                                 v-model="Result.num3"
                                label="Num3"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                        </template>
                     <!-- 4D -->
                        <template v-if="game == '4D'">
                        <v-col cols="3">
                             <v-text-field
                             type="number"
                                v-model="Result.num1"
                                label="Num1"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                         <v-col cols="3">
                             <v-text-field
                             type="number"
                                v-model="Result.num2"
                                label="Num2"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                         <v-col cols="3">
                             <v-text-field
                             type="number"
                                 v-model="Result.num3"
                                label="Num3"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                            
                        </v-col>
                         <v-col cols="3">
                             <v-text-field
                             type="number"
                                 v-model="Result.num4"
                                label="Num4"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                            
                        </v-col>
                        </template>
                       
                    </v-row>
               </v-form>
               <v-divider/>
          </v-card-text>
          <v-card-actions>
              <v-btn text small color="warning" @click="emitToParent('close')">
                 close
              </v-btn>
              <v-spacer/>
              <v-btn small text color="success" :loading="loading" @click="validate()">
                 DRAW
              </v-btn>
          </v-card-actions>
    </v-card>
     <va-confirm :show="confirm" :message="CONFIRM_MESSAGE" okbtn="Confirm" cancelbtn="Cancel" @ConfirmEvent="cdEvent"/>
  </v-dialog>
</template>

<script>
import { API_MIXINS } from '@/mixins/api_mixins.js'
export default {
    mixins: [API_MIXINS],
  props: {
      show:{
          type: Boolean,
          default: false
      },
      game: {
          type: String,
          default:''
      }
  },
  data () {
      return {
          loading: false,
          draw_id: '',
          confirm: false,
          Result:{},
          valid: true,
           nameRules: [
            v => !!v || '*Required'
        ],
      }
    },
  watch: {
     show(v) {
         if(v) {
             this.Result = {}
             this.draw_id = ''
         }
     }
  },
  computed:{
      CONFIRM_MESSAGE() {
          var message = ''
          switch (this.game ){
              case '3D':
                 message= "Confirm result: " + this.Result.num1 +" - " + this.Result.num2+" - " + this.Result.num3
            break;
              case '2D':
                 message = "Confirm result: " + this.Result.num1 +" - " + this.Result.num2
            break;
            case '4D':
                 message = "Confirm result: " + this.Result.num1 +" - " + this.Result.num2+" - " + this.Result.num3+" - " + this.Result.num4
            break;
            case 'SWER3':
                 message = "Confirm result: " + this.Result.num1 +" - " + this.Result.num2+" - " + this.Result.num3
            break;
             case 'SWER2':
                 message = "Confirm result: " + this.Result.num1 +" - " + this.Result.num2
            break;
             case '3DLOCAL':
                 message = "Confirm result: " + this.Result.num1 +" - " + this.Result.num2 +" - " + this.Result.num3
            break;
             case '2DLOCAL':
                 message = "Confirm result: " + this.Result.num1 +" - " + this.Result.num2 
            break;
             case 'PICK3':
                 message = "Confirm result: " + this.Result.num1 +" - " + this.Result.num2 +" - " + this.Result.num3+" - " + this.Result.num4+" - " + this.Result.num5+" - " + this.Result.num6
            break;
             case 'STLPARES':
                 message = "Confirm result: " + this.Result.num1 +" - " + this.Result.num2
            break;
             case 'STLLOCAL':
                 message = "Confirm result: " + this.Result.num1 +" - " + this.Result.num2
            break;
            default:
                message = ''
          }
         return message
      }
  },
  methods: {
      cdEvent(data) {
          this.confirm = false
          if('ok' == data) {
              this.loading = true
              this.keyIn()
          }
      },
   emitToParent (action) {
      this.$emit('DialogEvent', {action:action})
    },
    validate(){
        if (this.$refs.form.validate()) {
           this.confirm =true
        }
    },
    keyIn(){
        var param = {}
        param.game = this.game
        param.draw_id =  this.$IsNum(this.draw_id)
         if(this.game == 'SWER3') {
            param.Result = {
                num1: this.$IsNum(this.Result.num1),
                num2: this.$IsNum(this.Result.num2),
                num3: this.$IsNum(this.Result.num3)
            }
        }
        if(this.game == '3DLOCAL') {
            param.Result = {
                num1: this.$IsNum(this.Result.num1),
                num2: this.$IsNum(this.Result.num2),
                num3: this.$IsNum(this.Result.num3)
            }
        }
         if(this.game == 'PICK3') {
            param.Result = {
                num1: this.$IsNum(this.Result.num1),
                num2: this.$IsNum(this.Result.num2),
                num3: this.$IsNum(this.Result.num3),
                num4: this.$IsNum(this.Result.num4),
                num5: this.$IsNum(this.Result.num5),
                num6: this.$IsNum(this.Result.num6)
            }
        }
        if(this.game == '2DLOCAL') {
            param.Result = {
                num1: this.$IsNum(this.Result.num1),
                num2: this.$IsNum(this.Result.num2)
            }
        }
          if(this.game == 'SWER2' || this.game == 'STLPARES' || this.game == 'STLLOCAL') {
            param.Result = {
                num1: this.$IsNum(this.Result.num1),
                num2: this.$IsNum(this.Result.num2)
            }
        }
        if(this.game == '3D') {
            param.Result = {
                num1: this.$IsNum(this.Result.num1),
                num2: this.$IsNum(this.Result.num2),
                num3: this.$IsNum(this.Result.num3)
            }
        }
         if(this.game == '2D') {
            param.Result = {
                num1: this.$IsNum(this.Result.num1),
                num2: this.$IsNum(this.Result.num2)
            }
        }
         if(this.game == '4D') {
            param.Result = {
                num1: this.$IsNum(this.Result.num1),
                num2: this.$IsNum(this.Result.num2),
                num3: this.$IsNum(this.Result.num3),
                 num4: this.$IsNum(this.Result.num4)
            }
        }
        
            this.$http.post("draw/key_in", param).then(response => {
            response.data.status?this.VA_ALERT('success',response.data.message):this.VA_ALERT('error',response.data.message)
             this.loading = false
           this.$emit('DialogEvent', {action:'close'})
            }).catch(e => {
                console.log(e)
            })
        
    }
  }
}
</script>
