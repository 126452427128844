<template>
    <v-dialog
    v-model="show"
    persistent
    fullscreen>
   
    <v-card color="light_gray">
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-account-search</v-icon><span class="va-accopui">DATA FINDER</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text class="pa-4 def-font">
            <v-text-field
                      
                        v-model="searchStr"
                        :rules="nameRules"
                        label="Search Name"
                        required
                        outlined
                      ></v-text-field>
                      <div>
                        <template v-for="(item, index) in filteredItems">
                        <v-list-item two-line :key="index">
                          <v-list-item-content  @click="emitToParent(item)">
                            <v-list-item-title>{{ item.first_name }}, {{ item.surname }} {{ item.suffix }}</v-list-item-title>
                            <v-list-item-subtitle>{{ $moment(item.birth_date).format("YYYY-MMM-DD") }} </v-list-item-subtitle>
                            <v-list-item-subtitle>{{ item.address }} </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      </div>
          </v-card-text>
        
    </v-card>
  
  </v-dialog>
</template>

<script>
import { API_MIXINS } from '@/mixins/api_mixins.js'
export default {
    mixins: [API_MIXINS],
  props: {
      show:{
          type: Boolean,
          default: false
      },
  },
  data () {
      return {
        items:[],
        searchStr:"",
          draw_time:'',
          textform: false,
          action_type: '',
          text_header:"",
          valid: true,
          municipal:null,
          entry:{},
           nameRules: [
            v => !!v || '*Required'
        ],
      }
    },
  watch: {
     show(val) {
         if(val) {
           this.getItems()
         } 
     }
  },
  computed:{
    filteredItems(){
      var dataArr = []
        if(this.searchStr !=''){
          if(this.searchStr.length>2) {
            dataArr = this.items.reduce((res,item)=>{
              let fullname = item.first_name + ' ' + item.surname
              let index = fullname.search(new RegExp(this.searchStr, 'i'));
                if(index != -1) {
                res.push(item)
              }
              return res
            },[]) 
          }
        } 
        return dataArr
     }
  },
  methods: {
   emitToParent (action) {
      this.$emit('DialogEvent', action)
    },
    getItems() {
        this.$http.get("data/list").then(response => {
                    response.data.items != null?this.items = response.data.items:this.items=[]
                }).catch(e => {
                 console.log(e.data)
             })
      },
    validate(){
        if (this.$refs.form.validate()) {
         this.entry.Draws = this.draws
         console.log(this.entry)
         this.Save()
        }
    }
  }
}
</script>
