<template>
    <v-dialog
    v-model="show"
    persistent
    width="500">
   
    <v-card color="light_gray">
        <v-toolbar class="mid-font" color="success" dense dark >
            Confirm Result
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text class="pa-4 def-font">
              <p> Enter <strong class="va-underline">{{result}}</strong> as the fight result.</p>
               <v-form
                ref="form"
                autocomplete="off"
                v-model="valid"
                lazy-validation>
                    <v-text-field
                        v-model="textval"
                        class="login-input"
                        dense
                        label="Please enter result"
                        outlined
                        :rules="nameRules"
                        required
                    />
               </v-form>
          </v-card-text>
          <v-card-actions>
              <v-spacer/>
             <v-btn large color="success" @click="validate()">Confirm</v-btn>
          </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { API_MIXINS } from '@/mixins/api_mixins.js'
export default {
    mixins: [API_MIXINS],
  props: {
      show:{
          type: Boolean,
          default: false
      },
      result: {
           type: String,
          default: ''
      },
      header: {
           type: String,
          default: ''
      }
  },
   data () {
      return {
          textval: '',
          valid: true,
           nameRules: [
            v => !!v || '*Required'
        ],
      }
    },
  watch: {
      show(val) {
          if(!val) {
              this.textval = ''
          }
      }
  },
  computed:{
  },
  methods: {
   emitToParent (action) {
      this.$emit('DialogEvent', {action:action})
    },
     validate(){
        if (this.$refs.form.validate()) {
            if(this.textval.trim() == this.result.trim()) {
                this.$emit('DialogEvent', {action:this.textval.trim()})
            } else {
                    this.VA_ALERT('error', "Result not match!")
            }
            
        }
    },
  }
}
</script>
