<template>
  <v-app>
    <v-main :class="!IsLoggedIn?'loginbg':''">
      <core-toolbar  :class="windowClass" v-if="IsLoggedIn"/>
      <template v-if="currentRouteName != 'Households'">
      <core-drawer v-if="IsLoggedIn"/>
    </template>
      <core-view :class="windowClass"/>
         <core-footer/>
    </v-main>
  
       <v-snackbar
      v-model="showAlert"
      :color="alertColor"
      :timeout="2000"
       :top="'top'"
     >
      {{alertText }}
    </v-snackbar>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import {
  mapMutations
} from 'vuex'

export default {
  name: 'App',
  
  data: () => ({
  }),
  mounted () {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  computed:{
    currentRouteName() {
        return this.$route.name;
    },
      overlay() {
         return this.$store.state.overlay
      },
      showAlert: {
        get () {
          return this.$store.state.show_alert
          },
          set (val) {
            this.setAlert(val)
          }
      },
      alertText() {
        return this.$store.state.alert_text
      },
      alertColor(){
          return this.$store.state.alert_color
      },
      windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
      IsLoggedIn(){
         return this.$store.state.is_logged
      }
  },
  methods: {
    ...mapMutations(['setDrawer', 'setResponsive', 'setAlert']),
    
      onResponsiveInverted() {
          if (window.innerWidth < 991) {
           this.setResponsive(true)
          } else {
            this.setDrawer(true)
            this.setResponsive(false)
          }
    },
  }

};
</script>
<style>
@import '~vue-context/dist/css/vue-context.css';
/* .v-text-field--filled.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot {
  min-height: 36px !important;
} */
#bday {
  height: 300px;
  overflow-x: scroll;
}
@media print {
               .noprint {
                  visibility: hidden;
               }
            }
.v-input--dense .v-label {
   font-size: .91em !important;
}
  #va-drawer, #va-toolbar {
    text-transform: uppercase !important;
  }
  #va-drawer .v-list-item-group .v-list-item {
     /* transition: all 0.5s ease; */
    border-radius: 1.2em;
   
    margin: 4px 8px;
   }
   #va-drawer .v-list-item-group .v-list-item--active {
     /* transition: all 0.5s ease; */
    background: #6D9046;  
    box-shadow: 1px 2px #ddd;
    margin: 4px;
    color:#fff;
   }
  #va-drawer .v-list-item--active:hover::before,  #va-drawer .v-list-item--active::before {
     opacity: 0 ;
   }
  
  .va-not-reponsive {
    padding-left: 255px;
  }
  .va-table {
    width: 100%;
    text-align:  left;
  }
  .va-table  td {
  border-bottom: 1px solid #dedede;
    padding: 2px;
  }
  @media print {
    
    .va-table  thead td {
    font-size: .5em !important;
  }
   .va-table  tbody td {
    font-size: .6em !important;
    height: 10px !important;
  }
   .va-table  tfoot td {
    font-size: .5em !important;
  }
}
  .va-underline {
    border-bottom: 1px solid #F96332;
  }
  .v-select__slot, .v-text-field__slot {
      font-size: .75em !important;
  }
  .v-btn__content {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .va-right input{
    text-align: right;
  }

/* Drawer */
#va-drawer .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
#va-drawer .v-expansion-panels .v-expansion-panel {
  background: none !important;
  /* border-radius: 0 !important;
  -webkit-box-shadow:none !important; 
   box-shadow: none !important;  */
}
#va-drawer .v-expansion-panel::before {
  -webkit-box-shadow:none !important; 
   box-shadow: none !important;

}
.va-tab {
  display: block;
  text-align: left;
  width: 100%;
}
  .loginbg {
    background:#f1f1f1;
     /* background-image: url('assets/bg.png');
     background-size: 100% 100%; */
  }
  #loginbtn{
    background: rgb(241,241,241);
background: radial-gradient(circle, rgba(241,241,241,1) 0%, rgba(221,221,221,1) 100%);
    color: #3F96B9 !important;
  }

  .small-font {
    font-size:  .6em !important;
  }
  .md-font {
     font-size:  .9em !important;
  }
  .def-font {
     font-size:  .9em !important;
  }
  .lg-font {
     font-size:  1.2em !important;
  }
  .color-light {
    color: #b5b5b5;
  } 
 #roomprop .v-expansion-panel-content__wrap {
   padding: 0 !important;
  }

.border_bot {
  border-bottom: 1px solid #eee;
}

  .theme--light.v-input--is-disabled input {
    color: #000 !important;
  }
  .light_text {
    color: #ddd;
  }
  .shadow-text {
     text-shadow: 1px 1px #555;
  }
  .print_table {
    width: 100%;
    font-size: .8em;
    text-align: left;
  }
   .print_table  th {
     font-weight: normal;
   }
    .print_table  th,  .print_table  td {
      padding: 2px;
      border-bottom: 1px solid #c5c5c5;
    }
    .text-secondary {
  color: #19365A;
}
.text-success {
  color: #5cb860;
}
.text-error {
  color: #f55a4e;
}
.text-primary {
  color: #68BE9F;
}
.text-warning {
  color: #ffa21a;
}
.text-info {
  color: #00d3ee;
}
.text-accent {
  color: #82B1FF;
}
.text-cancelled {
    opacity: .6;
}
.text-gray {
  color: #353535;
}

.text-wala {
  color: #372DCC;
}
.text-meron, .text-Lost {
  color: #CC232A;
}
.text-draw, .text-Win  {
  color: #5CB860;
}
.text-cancelled {
  color: #757575;
}
@keyframes preload {
    0% {
      opacity: 1
    }
    50% {
      opacity: .4
    }
    100% {
      opacity: 1
    }
  }


</style>