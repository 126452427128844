<template>

  <v-dialog
    v-model="show"
    persistent
    max-width="420">
    <v-card>
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-account-box</v-icon><span class="va-underline">{{state=='new'?'New':'Edit'}} Franchisee</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
        <v-card-text>
             <v-form
                ref="form"
                class="mt-4"
                v-model="valid"
                lazy-validation
              >
              <v-row>
                <v-col cols="12">
                      <v-text-field
                 outlined
                  dense
                 v-model="item.area"
                  label="Area Covered"
                  :rules="textRules"
                required
                ></v-text-field>
               <v-text-field
                 v-model="item.company"
                  label="Company/Individual *"
                  outlined
                  dense
                  :rules="textRules"
                required
                ></v-text-field>
                 <v-text-field
                 v-model="item.contact_person"
                  label="Contact Person *"
                  outlined
                  dense
                  :rules="textRules"
                required
                ></v-text-field>
                <v-text-field
                 outlined
                  dense
                 v-model="item.company_addr"
                  label="Company Address"
                  :rules="textRules"
                required
                ></v-text-field>

                 <v-text-field
                 outlined
                  dense
                 v-model="item.contact_no"
                  label="Contact No."
                  :rules="textRules"
                required
                ></v-text-field>
                <v-text-field
                  v-model="item.email"
                   outlined
                  dense
                  label="Email"
                ></v-text-field>
                <v-autocomplete
                  v-model="item.status"
                   :rules="textRules"
                required
                  :items="ROLES"
                  outlined
                    dense
                  label="Status  "
                  ></v-autocomplete> 
                </v-col>
              </v-row>
             </v-form>
        </v-card-text>
        <v-card-actions>
                <v-btn color="warning" small rounded text @click="emitToParent('close')">CLOSE</v-btn>
            <v-spacer></v-spacer>
            <v-btn  small color="success"  depressed  @click="validate()" v-if="ALLOW('update_branch_franchisee')">{{BTN_TEXT}}</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    },
    selectedID: {
       type: Number,
        default: null
    },
    state: {
        type: String,
        default: "new"
    }
  },
  data: () => ({
      valid: true,
      newpassword:'',
      show1: false,
      item: {},
      textRules: [
        v => !!v || 'Field is required'
      ],
  }),
  computed: {
      BTN_TEXT() {
          if(this.state == 'new')
            return "ADD"
         else 
            return "SAVE"
      },
      ROLES() {
        return ['New Application', 'Verified', "Closed"]
      }
  },
  watch: {
    show (v) {
      if (v) {
        this.item = {}
         if(this.state != 'new') { this.getItem() }
      } 
    }
  },
  methods: {
      validate () {
        if (this.$refs.form.validate()) {
            this.state=='new'?this.Add():this.Update()
        }
      },
       Add: function() {
        var param = {}
        var entries = Object.entries(this.item)
        for (const [keyname, value] of entries) {
          param[keyname] = this.$Filter(value)
        }
        console.log(param)
        this.$http.post("franchisee/new", param).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message) : this.VA_ALERT('error', response.data.message) 
            this.$emit('DialogEvent', "")
        }).catch(e => {
          console.log(e)
        })
     },
      Update: function() {
       
        this.$http.post("franchisee/update", this.item).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message) : this.VA_ALERT('error', response.data.message) 
            this.$emit('DialogEvent', "")
        }).catch(e => {
          console.log(e)
        })
     },
     getItem: function() {
        this.$http.post("franchisee/get", {id:this.selectedID}).then(response => {
            response.data.status? this.item = response.data.item : this.item = {}
           console.log(this.item)
        }).catch(e => {
          console.log(e)
        })
     },
    emitToParent () {
        this.$emit('DialogEvent', "")
    }
  }
}
</script>
