<template>

  <v-dialog
    v-model="show"
    persistent
    max-width="350">
    <v-card>
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-swap-horizontal-bold</v-icon><span class="va-underline">Transfer Load</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
        <v-card-text>
             <v-form
                ref="form"
                class="mt-4"
                v-model="valid"
                lazy-validation
              >
              <v-row>
                <v-col cols="12">
                   <v-autocomplete
                      v-model="branch"
                      item-text="name"
                      return-object
                      :rules="textRules"
                      required
                      :items="$store.state.branches"
                      outlined
                      dense
                      label="Select branch"
                  ></v-autocomplete> 
                  
                   <v-text-field
                  outlined
                  dense
                  type="number"
                  v-model="item.amount"
                  label="Enter Amount"
                  :rules="textRules"
                  required
                  ></v-text-field>
                </v-col>
              </v-row>
              
             </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn  small color="success"  depressed  @click="validate()">Transfer Load</v-btn>
        </v-card-actions>
    </v-card>
    <va-confirm :show="confirm" :message="confirmMsg" :okbtn="confirmOkbtn" :cancelbtn="confirmCancelbtn" @ConfirmEvent="cEvent"/>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    },
    state: {
        type: String,
        default: "new"
    }
  },
  data: () => ({
      valid: true,
      todo:null,
      confirmMsg: "",
      confirmOkbtn: "",
      confirmCancelbtn: "", 
      confirm: false,
      newpassword:'',
      pad: false,
      show1: false,
      item: {},
      set_status:"Requested",
      branch:{},
      latlong:{},
      signature:null,
      franchiselist:[],
      textRules: [
        v => !!v || 'Field is required'
      ],
  }),
  computed: {
     
     
  },
  watch: {
    show (v) {
      if (v) {
          this.branch = {}
          var account = this.$session.get("account")
          this.item = {}
          this.item.type= "Load Transfer"
          this.item.from_account_id = parseInt(account.franchisee_id)
          this.item.from_account_type = account.role
          this.item.to_account_type = "Branch"
         
         
      } 
    }
  },
  methods: {
    set_confirm() {
      this.confirmMsg = "Are you sure to complete this transfer?"
      this.confirmOkbtn ="Transfer"
      this.confirmCancelbtn ="Not Now"
      this.confirm = true
    },
    cEvent(data) {
      this.confirm = false
      if(data == "ok") {
        this.SendRequest()
      }
    },
    validate () {
        if (this.$refs.form.validate()) {
            this.set_confirm()
        }
      },
     
      SendRequest: function() {
        this.item.amount = this.$IsNum(this.item.amount)
        this.item.to_account_id = parseInt(this.branch.id)
         this.item.to_account_name =  this.branch.name
        this.$http.post("wallet/new_transaction", this.item).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message) : this.VA_ALERT('error', response.data.message) 
            this.$emit('DialogEvent', "")
        }).catch(e => {
          console.log(e)
        })
     },
    emitToParent () {
        this.$emit('DialogEvent', "")
    }
  }
}
</script>
