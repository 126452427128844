export default [ 
    "accountant",
    "actor",
    "actuary",
    "adhesive bonding machine tender",
    "adjudicator",
    "administrative assistant",
    "administrative services manager",
    "aerobics instructor",
    "aerospace engineering technician",
    "agent",
    "agricultural",
    "air conditioning",
    "aircraft service technician",
    "airline pilot",
    "animal breeder",
    "animator",
    "anthropologist",
    "apparel worker",
    "archeologist",
    "architect",
    "artist",
    "assembler",
    "athlete",
    "athletic trainer",
    "attendant",
    "auditor",
    "author",
    "automotive",
    "baker",
    "barber",
    "bartender",
    "bellhop",
    "business and entrepreneur",
    "biochemist",
    "biophysicist",
    "boilermaker",
    "bookkeeper",
    "broadcast news analyst",
    "butcher",
    "cabinetmaker",
    "cafeteria attendant",
    "cafeteria cook",
    "carpenter",
    "cashier",
    "caster",
    "cement mason",
    "checker",
    "chemical engineer",
    "chemist",
    "chief executive",
    "childcare worker",
    "choreographer",
    "civil engineer",
    "cleaner",
    "clinical laboratory",
    "composer",
    "computer programmer",
    "construction inspector",
    "construction manager",
    "construction painter",
    "construction worker",
    "cook",
    "counselor",
    "dancer",
    "data entry keyer",
    "database administrator",
    "demonstrator",
    "dentist",
"dermatologist",
    "designer",
    "detective",
    "director",
    "dishwasher",
    "dispatcher",
"DJ",
"doctor",
"driver",
    "economist",
    "editor",
    "electrical engineer",
    "electrical installer",
    "electrical power-line installer",
    "electrician",
    "engineer",
    "event planner",
    "farmer",
    "fashion designer",
    "fisher",
    "fitness trainer",
    "flight attendant",
    "furniture finisher",
    "gaming",
    "groundskeeper",
    "hairstylist",
    "hotel desk clerk",
    "illustrator",
    "information clerk",
    "inspector",
    "instructional coordinator",
    "instructor",
    "internist",
    "interpreter",
    "interviewer",
    "investigator",
    "jailer",
    "janitor",
    "jeweler",
    "judge",
    "judicial law clerk",
    "kettle operator",
    "kiln operator",
    "labor",
    "landscape architect",
    "landscaping worker",
    "laundry worker",
    "lawyer",
    "legislator",
    "librarian",
    "library assistant",
    "lifeguard",
    "logging equipment operator",
    "logistician",
    "maid",
    "maintenance worker",
    "makeup artist",
    "manicurist",
    "marketing manager",
    "marketing specialist",
    "mechanical door repairer",
    "mechanical engineer",
    "mediator",
    "medical assistant",
    "meeting planner",
    "messenger",
    "mining",
    "model",
"movers",
    "multimedia artist",
    "musician",
    "nurse",
    "office clerk",
    "painter ",
    "pest control worker",
    "pesticide applicator",
    "pharmacist",
    "physician",
    "plumber",
    "podiatrist",
    "poultry",
    "priest",
    "printing press",
    "radio operator",
    "radiologic",
    "real estate",
    "receptionist",
    "referee",
    "religious worker",
    "rental clerk",
    "sales representative",
    "secretary",
    "security guard",
    "shipmate",
    "shoe worker",
    "software developer",
    "surgeon",
    "tailor",
    "teacher",
    "teller",
    "therapist",
    "UX designer",
    "veterinarian",
    "waiter",
    "waitress",
    "web developer",
    "weigher",
    "welder",
    "zoologist"
]